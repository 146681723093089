import React, { Component } from 'react';
import { Link } from "react-router-dom";
import avatar from "./../../assets/Images/avatar.png";
import lock from "./../../assets/Images/lock.png";
import eye from "./../../assets/Images/eye.png";

class userLogin extends Component {
    render() {
        return (
            <div className="outer-sign-in">
                <div className="text-center w-100">
                    <h1 className="logo-text">INSTANT PAY</h1>
                    <div className="sign-in-card">
                        <label className="sign-in">Sign In</label>
                        <form name="sign-in-form">
                            <div className="input-cntr">
                                <div className="input-icons">
                                    <img src={avatar} alt="icon missing" />
                                </div>
                                <input type="text" placeholder="Enter Email ID" />
                            </div>
                            <div className="input-cntr">
                                <div className="input-icons">
                                    <img src={lock} alt="icon missing" />
                                </div>
                                <input type="password" placeholder="Enter Password" />
                                <div className="input-icons cursor-pointer m-0 ml-2">
                                    <img src={eye} alt="icon missing" />
                                </div>
                            </div>
                            <div className="flex-parted">
                                <span></span>
                                <Link to="ForgotPassword" className="font-14-500 color-dark-blue">
                                    Forgot Password ?
                                </Link>
                            </div>
                            <button type="submit" className="butn mx-auto">Sign In</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default userLogin
