import React, { Component } from "react";
import { Table, Popover } from "antd";
import CSV from "./../../../assets/Images/csv.png";
import InfoIcon from "./../../../assets/Images/Infoblue.png";
import Filter from "./../../../assets/Images/filter.png";
import WhiteDropdown from "./../../../assets/Images/WhiteDropdown.png";
import config from "./../../../helpers/config";
import axios from "axios";
import { authHeader } from "./../../../helpers/authHeader";
import Visa from "./../../../assets/Images/visa.png";
import MasterCard from "./../../../assets/Images/mastercard.png";
import AmericanExpress from "./../../../assets/Images/american-express.png";
import DinerClub from "./../../../assets/Images/diners-club.png";
import { Drawer } from "antd";
import Down from "./../../../assets/Images/download.png";

class paymentManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilter: false,
      customerTransactionsData: [],
      merchantTransactionsData: [],
      activeTab: "customer",
      visibleFilter: false,
      placement: "bottom",
      mobileView: false,
    };
  }
  showDrawerFilter = () => {
    this.setState({ visibleFilter: true });
  };
  onCloseFilter = () => {
    this.setState({ visibleFilter: false });
  };
  onChange = (e) => {
    this.setState({ placement: e.target.value });
  };
  componentDidMount() {
    this.handleGetCustomerTransactionsList();
    if (window.screen.width > 768) {
      this.setState({
        mobileView: false,
      });
    } else {
      this.setState({
        mobileView: true,
      });
    }
  }

  handleInstallmentsDetails(installmentsDetails) {
    debugger;

    this.props.history.push({
      pathname: "installmentDetails",
      installmentsDetails: installmentsDetails,
    });
  }

  handleGetCustomerTransactionsList() {
    let self = this;

    axios({
      method: "post",
      url: config.apiUrl + "PaymentManagement/CustomerTransactionManagement",
      headers: authHeader(),
      data: {
        OrderID: null,
        TransactionID: null,
        TransactionDateFrom: null,
        TransactionDateTo: null,
        TransactionAmount: 0.0,
        UserName: null,
        MerchantName: null,
        PaymentProcessor: null,
        PaymentMethod: null,
        CustomerCard: null,
        PaymentStatus: null,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            customerTransactionsData: data,
          });
        } else {
          self.setState({
            customerTransactionsData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleGetMerchantTransactionsList() {
    let self = this;

    axios({
      method: "post",
      url: config.apiUrl + "PaymentManagement/MerchantTransactionManagement",
      headers: authHeader(),
      data: {
        TransactionID: null,
        TransactionDateFrom: null,
        TransactionDateTo: null,
        TransactionAmount: 0.0,
        MerchantName: null,
        PaymentProcessor: null,
        PaymentMethod: null,
        PaymentStatus: null,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            merchantTransactionsData: data,
          });
        } else {
          self.setState({
            merchantTransactionsData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleUserDetails(userId) {
    debugger;

    this.props.history.push({
      pathname: "adminUserManagementDetails",
      userId: userId,
    });
  }

  handleMerchantDetails(merchantId) {
    debugger;

    this.props.history.push({
      pathname: "adminMerchantManagementDetails",
      merchantId: merchantId,
    });
  }

  handleTabChange(tabName) {
    const { activeTab } = this.state;
    if (tabName !== activeTab) {
      if (tabName === "merchant") {
        this.handleGetMerchantTransactionsList();
        this.setState({
          activeTab: "merchant",
        });
      } else {
        this.handleGetCustomerTransactionsList();
        this.setState({
          activeTab: "customer",
        });
      }
    }
  }

  handleFilterbuttonClick = () => {
    this.setState({ isFilter: !this.state.isFilter });
  };
  render() {
    const { placement, visibleFilter } = this.state;
    const columnsCustomer = [
      {
        title: "No.",
        dataIndex: "no",
        key: "no",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
      },
      {
        title: "Trasaction ID",
        dataIndex: "transactionID",
        key: "transactionID",
      },
      {
        title: "Trasaction Date",
        dataIndex: "transactionDate",
        key: "transactionDate",
      },
      {
        title: "Installments",
        dataIndex: "installment",
        key: "installment",
        render: (row, item) =>
          item.installment === "-" ? (
            item.installment
          ) : (
            <span
              className="custom-link"
              onClick={() =>
                this.handleInstallmentsDetails({
                  orderId: item.orderId,
                  merchantId: item.merchantId,
                  userId: item.userId,
                })
              }
            >
              {item.installment}
            </span>
          ),
      },
      {
        title: "Amount Transacted (AU$)",
        dataIndex: "transactionAmount",
        key: "transactionAmount",
      },
      {
        title: "User",
        dataIndex: "userName",
        key: "userName",
        render: (row, item) => (
          <span
            className="custom-link"
            onClick={() => this.handleUserDetails(item.userId)}
          >
            {item.userName}
          </span>
        ),
      },
      {
        title: "Merchant",
        dataIndex: "merchantName",
        key: "merchantName",
        render: (row, item) => (
          <span
            className="custom-link"
            onClick={() => this.handleMerchantDetails(item.merchantId)}
          >
            {item.merchantName}
          </span>
        ),
      },
      {
        title: "Payment Processor",
        dataIndex: "paymentProcessor",
        key: "paymentProcessor",
      },
      {
        title: "Payment Method",
        dataIndex: "customerCard",
        key: "customerCard",
        render: (row, item) =>
          item.customerCard.toLowerCase().trim() === "visa" ? (
            <img src={Visa} alt="icon missing" className="cards-icon" />
          ) : item.customerCard.toLowerCase().trim() === "master card" ? (
            <img src={MasterCard} alt="icon missing" className="cards-icon" />
          ) : item.customerCard.toLowerCase().trim() === "american express" ? (
            <img
              src={AmericanExpress}
              alt="icon missing"
              className="cards-icon"
            />
          ) : item.customerCard.toLowerCase().trim() === "diner club" ? (
            <img src={DinerClub} alt="icon missing" className="cards-icon" />
          ) : (
            item.customerCard
          ),
      },
      {
        title: "Status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        render: (row, item) => {
          return (
            <div className="amazontext">
              <label
                className={
                  item.paymentStatus.toLowerCase().trim() === "failed"
                    ? "failed"
                    : item.paymentStatus.toLowerCase().trim() === "success"
                    ? "success"
                    : item.paymentStatus.toLowerCase().trim() === "progress"
                    ? "custom-link"
                    : "refundtext"
                }
              >
                {item.paymentStatus}
              </label>
              <Popover
                content={
                  <div className="refundpopover">
                    <div className="subsc">
                      <label>Refund Amount</label>
                      <label>{item.refundAmount}</label>
                    </div>
                    <div className="subsc">
                      <label>Refund Date</label>
                      <label>{item.refundedDate}</label>
                    </div>
                    <div className="subsc">
                      <label>Refund Initiated By</label>
                      <label>{item.refundInitiatedBy}</label>
                    </div>
                  </div>
                }
                placement="bottom"
                trigger="hover"
              >
                {item.paymentStatus === "Refunded" && (
                  <img src={InfoIcon} alt="InfoIcon" />
                )}
              </Popover>
            </div>
          );
        },
      },
    ];
    const columnsMerchant = [
      {
        title: "No.",
        dataIndex: "no",
        key: "no",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Trasaction ID",
        dataIndex: "transactionID",
        key: "transactionID",
      },
      {
        title: "Trasaction Date",
        dataIndex: "transactionDate",
        key: "transactionDate",
        className: "mob-none",
      },
      {
        title: "Amount Transacted (AU$)",
        dataIndex: "transactionAmount",
        key: "transactionAmount",
        className: "mob-none",
      },
      {
        title: "Merchant",
        dataIndex: "merchantName",
        key: "merchantName",
        className: "mob-none",
        render: (row, item) => (
          <span
            className="custom-link"
            onClick={() => this.handleMerchantDetails(item.merchantId)}
          >
            {item.merchantName}
          </span>
        ),
      },
      {
        title: "Payment Processor",
        dataIndex: "paymentProcessor",
        key: "paymentProcessor",
        className: "mob-none",
      },
      {
        title: "Payment Method",
        dataIndex: "customerCard",
        key: "customerCard",
        className: "mob-none",
        render: (row, item) =>
          item.customerCard.toLowerCase().trim() === "visa" ? (
            <img src={Visa} alt="icon missing" className="cards-icon" />
          ) : item.customerCard.toLowerCase().trim() === "master card" ? (
            <img src={MasterCard} alt="icon missing" className="cards-icon" />
          ) : item.customerCard.toLowerCase().trim() === "american express" ? (
            <img
              src={AmericanExpress}
              alt="icon missing"
              className="cards-icon"
            />
          ) : item.customerCard.toLowerCase().trim() === "diner club" ? (
            <img src={DinerClub} alt="icon missing" className="cards-icon" />
          ) : (
            item.customerCard
          ),
      },
      {
        title: "Status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        render: (row, item) => {
          return (
            <div className="amazontext">
              <label
                className={
                  item.paymentStatus.toLowerCase().trim() === "failed"
                    ? "failed"
                    : item.paymentStatus.toLowerCase().trim() === "success"
                    ? "success"
                    : item.paymentStatus.toLowerCase().trim() === "progress"
                    ? "custom-link"
                    : "refundtext"
                }
              >
                {item.paymentStatus}
              </label>
              <Popover
                content={
                  <div className="refundpopover">
                    <div className="subsc">
                      <label>Refund Amount</label>
                      <label>{item.refundAmount}</label>
                    </div>
                    <div className="subsc">
                      <label>Refund Date</label>
                      <label>{item.refundedDate}</label>
                    </div>
                    <div className="subsc">
                      <label>Refund Initiated By</label>
                      <label>{item.refundInitiatedBy}</label>
                    </div>
                  </div>
                }
                placement="bottom"
                trigger="hover"
              >
                {item.paymentStatus === "Refunded" && (
                  <img src={InfoIcon} alt="InfoIcon" />
                )}
              </Popover>
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "Action",
        render: (row, item) => {
          return item.paymentStatus.toLowerCase().trim() === "refund init" ? (
            <Popover
              content={
                <div className="deletepopover text-center">
                  <h3>Are you sure to refund ?</h3>
                  <button className="delete">Cancel</button>
                  <button className="refundbt">Refund</button>
                </div>
              }
              placement="bottomRight"
              trigger="click"
            >
              <div className="refund">
                <button>Refund</button>
              </div>
            </Popover>
          ) : (
            "-"
          );
        },
      },
    ];
    return (
      <div className="paymentmana">
        <h3 className="Usermana">Transaction Management</h3>
        <div className="exfilter">
          <label className="csv">
            <img src={CSV} alt="Export" />
            Export to CSV
          </label>
          <label className="filte" onClick={this.handleFilterbuttonClick.bind(this)}>
            <img src={Filter} alt="Export" />
            Filter
            <img src={WhiteDropdown} alt="Dropdown" className="WhDrop" />
          </label>
        </div>
        <label className="filt" onClick={this.showDrawerFilter.bind(this)}>
          <img src={Filter} alt="Export" />
        </label>
        {this.state.isFilter ? (
          <div className="row m-0 w-100 back">
            <div className="col-12 col-md-3">
              <input type="text" placeholder="Enter User Id" />
            </div>
            <div className="col-12 col-md-3">
              <input type="text" placeholder="Enter Name" />
            </div>
            <div className="col-12 col-md-3">
              <input type="text" placeholder="Enter Email" />
            </div>
            <div className="col-12 col-md-3">
              <input type="text" placeholder="Enter Contact No." />
            </div>
            <div className="col-12 col-md-3">
              <label className="Totalamount">
              Select Total No. of Txn upto
              </label>
              <div className="slidecontainer">
                <input type="range" min="1" max="100" value="50" />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <label className="Totalamount">
                Select Total Amount Txn upto
              </label>
              <div className="slidecontainer">
                <input type="range" min="1" max="100" value="50" />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <input
                type="text"
                className="calendar"
                placeholder="Start Date - End Date"
              />
            </div>
            <div className="col-12 col-md-3">
              <select>
                <option>Select Status</option>
                <option>Select Status 1</option>
                <option>Select Status 2</option>
              </select>
            </div>
            <div className="col-12 col-md-12">
              <div className="search">
                <button>Search</button>
              </div>
            </div>
          </div>
        ) : null}
        <div className="paytab">
          <div className="secondtab mb-4 mt-4 w-100">
            <ul className="nav nav-tabs w-100" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#noti-Tab"
                  role="tab"
                  aria-controls="noti-Tab"
                  aria-selected="true"
                  onClick={() => this.handleTabChange("customer")}
                >
                  End Customer
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#email-Tab"
                  role="tab"
                  aria-controls="email-Tab"
                  aria-selected="false"
                  onClick={() => this.handleTabChange("merchant")}
                >
                  Merchant
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div
              className="tab-pane fade active show"
              id="noti-Tab"
              role="tabpanel"
              aria-labelledby="noti-Tab"
            >
              <div className="paymenttable">
                <Table
                  columns={columnsCustomer}
                  expandedRowRender={(row) => {
                    return (
                      <React.Fragment>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">OrderId:</label>
                              <label className="expandemailtext">{row.orderId}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">transactionID:</label>
                              <label className="expandemailtext">{row.transactionID}</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Trasaction Date:</label>
                              <label className="expandemailtext">{row.transactionDate}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Installments:</label>
                              <label className="expandemailtext">{row.installment}</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Amount Transacted (AU$):</label>
                              <label className="expandemailtext">{row.transactionAmount}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">User:</label>
                              <label className="expandemailtext">{row.userName}</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">MerchantName:</label>
                              <label className="expandemailtext">{row.merchantName}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Payment Processor:</label>
                              <label className="expandemailtext">{row.paymentProcessor}</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">customerCard:</label>
                              <label className="expandemailtext">{row.customerCard}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Created By:</label>
                              <div className="amazontext">
                                <label className="expandemailtext">
                                  {row.createBy}
                                </label>
                                <Popover
                                  content={
                                    <div className="userpopover">
                                      <div className="subsc">
                                        <label>Created By</label>
                                        <label>{row.createBy}</label>
                                      </div>
                                      <div className="subsc">
                                        <label>Created On</label>
                                        <label>{row.createDate}</label>
                                      </div>
                                      <div className="subsc">
                                        <label>Modified By</label>
                                        <label>{row.updateBy}</label>
                                      </div>
                                      <div className="subsc">
                                        <label>Modified On</label>
                                        <label>{row.updateDate}</label>
                                      </div>
                                    </div>
                                  }
                                  placement="bottom"
                                  trigger="click"
                                >
                                  <img src={InfoIcon} alt="InfoIcon" />
                                </Popover>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Status:</label>
                              <label className="expandemailtext">
                                {row.isActive ? "Active" : "Inactive"}
                              </label>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }}
                  expandIcon={ ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <div className="expandown1">
                      <img src={Down} onClick={e => onExpand(record, e)} />
                    </div>
                  ) : (
                    <div className="expandown">
                      <img src={Down} onClick={e => onExpand(record, e)} />
                    </div>
                  )}
                  expandIconColumnIndex={this.state.mobileView ? 8 : -1}
                  expandIconAsCell={false}
                  dataSource={this.state.customerTransactionsData}
                  pagination={{position:["bottomCenter"]}}
                />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="email-Tab"
              role="tabpanel"
              aria-labelledby="email-Tab"
            >
              <div className="paymenttable">
                <Table
                  columns={columnsMerchant}
                  dataSource={this.state.merchantTransactionsData}
                  pagination={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="fl">
          <Drawer
            placement={placement}
            closable={false}
            onClose={this.onCloseFilter}
            visible={visibleFilter}
            key={placement}
            className="f2"
          >
            <div className="drarfilter">
              <div className="row m-0 w-100 back">
                <div className="col-12 col-md-3">
                  <input type="text" placeholder="Enter User Id" />
                </div>
                <div className="col-12 col-md-3">
                  <input type="text" placeholder="Enter Name" />
                </div>
                <div className="col-12 col-md-3">
                  <input type="text" placeholder="Enter Email" />
                </div>
                <div className="col-12 col-md-3">
                  <input type="text" placeholder="Enter Contact No." />
                </div>
                <div className="col-12 col-md-3">
                  <label className="Totalamount">
                    Total No. of Transaction upto
                  </label>
                  <div className="slidecontainer">
                    <input type="range" min="1" max="100" value="50" />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <label className="Totalamount">
                    Total Amount Transacted upto
                  </label>
                  <div className="slidecontainer">
                    <input type="range" min="1" max="100" value="50" />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <input
                    type="text"
                    className="calendar"
                    placeholder="Start Date - End Date"
                  />
                </div>
                <div className="col-12 col-md-3">
                  <select>
                    <option>Select Status</option>
                    <option>Select Status 1</option>
                    <option>Select Status 2</option>
                  </select>
                </div>
                <div className="col-12 col-md-12">
                  <div className="search">
                    <button onClick={this.onCloseFilter.bind(this)} className="mr-1">Cancel</button>
                    <button>Search</button>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

export default paymentManagement;
