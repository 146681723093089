import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "./../../helpers/config";
import axios from "axios";

class faq extends Component {
    constructor(props) {
        super(props);

        this.state = {
            staticContent: [],
        };
    }

    componentDidMount() {
        this.handleGetStaticContent();
    }

    handleGetStaticContent() {
        let self = this;

        axios({
            method: "get",
            url: config.apiUrl + "MerchantMarketing/FAQDetails",
        })
            .then(function (res) {
                debugger;
                let data = res.data.responseData;
                self.setState({
                    staticContent: data,
                });
            })
            .catch((data) => {
                console.log(data);
            });
    }

    hanlecallback(key) {
        console.log(key);
    }
    render() {
        const { staticContent } = this.state;
        return (
            <div className="marketingApi">
                <div className="merchantMar">
                    <header>
                        <div className="inpay">
                            <Link to="/home">
                                <h4>Instant Pay</h4>
                            </Link>
                        </div>
                        <div className="foryou">
                            <ul>
                                <li>
                                    <Link to="/userSignUp">
                                        <a>For You</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/signUp">
                                        <a>For Your Business</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/setUp">
                                        <a>Set - Up</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/help">
                                        <a>Help</a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="signbtn">
                            <Link to="/">
                                <button type="button" className="btn sigin">
                                    Sign In
                                </button>
                            </Link>
                            <Link to="/signUpHome">
                                <button type="button" className="btn sigup">
                                    Sign Up
                                </button>
                            </Link>
                        </div>
                    </header>
                </div>
                <div className="container topbottom">
                    <h3>FAQ</h3>
                    <div className="apitab">
                    {staticContent.map((item, i) => (
                        <><p className="mt-2">{i+1}{". "}{item.question}</p>
                        <p className="mt-2">{item.answer}</p></>
                    ))}
                    </div>
                </div>
                <div className="foot">
                    <footer className="container">
                        <div className="inpay">
                            <h4>Instant Pay</h4>
                            {/* <span>&copy; 1999-2020</span>
                            <a>Privacy Policy Terms and Conditions</a> */}
                        </div>
                        <div className="foryou">
                            <ul>
                                <li>
                                    <Link to="/aboutUs">
                                        <p>About Us</p>
                                    </Link>
                                    <Link to="/contactus">
                                        <p>Contact Us</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/policies">
                                        <p>Policies</p>
                                    </Link>
                                    <Link to="/faq">
                                        <p>FAQ</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/apiInformation">
                                        <p>API Information</p>
                                    </Link>
                                    <Link to="/termsAndCondition">
                                        <p>Terms & Conditions</p>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="signbtn">
                            <ul className="footerlink">
                                <li>
                                    <a className="fa fa-facebook" href="https://www.facebook.com/" target="_blank"></a>
                                </li>
                                <li>
                                    <a className="fa fa-twitter" href="https://twitter.com/" target="_blank"></a>
                                </li>
                                <li>
                                    <a className="fa fa-linkedin" href="https://in.linkedin.com/" target="_blank"></a>
                                </li>
                                <li>
                                    <a className="fa fa-instagram" href="https://www.instagram.com/" target="_blank"></a>
                                </li>
                                <li>
                                    <a className="fa fa-youtube-play" href="https://www.youtube.com/" target="_blank"></a>
                                </li>
                            </ul>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default faq;
