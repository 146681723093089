import React, { Component } from "react";
import { Collapse } from "antd";
import RedDelete from "./../../../assets/Images/delete.png";
import BlueEdit from "./../../../assets/Images/editt.png";
import refresh from "./../../../assets/Images/refresh.png";
import checklist from "./../../../assets/Images/checklist.png";
import conversation from "./../../../assets/Images/chat.png";
import insurance from "./../../../assets/Images/insurance.png";
import facebook from "./../../../assets/Images/facebook.png";
import instagram from "./../../../assets/Images/instagram.png";
import plus from "./../../../assets/Images/add.png";
import config from "./../../../helpers/config";
import axios from "axios";

class contentManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  hanlecallback(key) {
    console.log(key);
  }

  handleGetStaticContent() {
    let self = this;

    axios({
      method: "get",
      url: config.apiUrl + "MerchantMarketing/ContentManagementDetails",
    })
      .then(function (res) {
        debugger;
        let data = res.data.responseData;
        self.setState({
          staticContent: data,
        });
      })
      .catch((data) => {
        console.log(data);
      });
  }
  
  render() {
    const { Panel } = Collapse;
    return (
      <div className="contanentmanagement">
        <h3 class="Usermana">Content Management</h3>
        <Collapse
          defaultActiveKey={["1"]}
          onChange={this.hanlecallback.bind(this)}
        >
          <Panel
            header={
              <div>
                <img className="iconsizectmm" src={refresh} />
                <label className="ml-3">App Version</label>
                <img className="updnimg" src={plus} />
              </div>
            }
            key="1"
          >
            {/* <p>Sample 1</p> */}
            <div>
              <form>
                <div class="row">
                  <div class="col-2">
                    <label className="fsizectmm">App Version</label>
                  </div>
                  <div class="col-4">
                    <input type="tel" className="form-control" placeholder="" />
                  </div>
                  <div class="col-2">
                    <img className="editimg" src={BlueEdit} alt="edit" />{" "}
                    {/* <button type="submit" className="btn btn-primary">Edit</button> */}{" "}
                  </div>
                </div>
              </form>
            </div>
          </Panel>
          <Panel
            header={
              <div>
                <img className="iconsizectmm" src={refresh} />
                <label className="ml-3">Social Media Links</label>
                <img className="updnimg" src={plus} />
              </div>
            }
            key="2"
          >
            {/* <p>Sample 2</p> */}
            <div>
              <form>
                <div class="row">
                  <div class="col-1">
                    <img src={facebook} alt="facebook" />
                  </div>
                  <div class="col-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Last name"
                    />
                  </div>
                  <div class="col-1">
                    <img className="editimg" src={BlueEdit} alt="edit" />
                  </div>
                  <div class="col-1">
                    <img src={instagram} alt="instagram" />
                  </div>
                  <div class="col-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First name"
                    />
                  </div>
                  <div class="col-1">
                    <img className="editimg" src={BlueEdit} alt="edit" />
                  </div>
                </div>
              </form>
            </div>
          </Panel>
          <Panel
            header={
              <div>
                <img className="iconsizectmm" src={checklist} />
                <label className="ml-3">Terms & Conditions</label>
                <img className="updnimg" src={plus} />
              </div>
            }
            key="3"
          >
            {/* <p>Sample 3</p> */}
            <div>
              <div className="dflex">
                <label className="dflex nosize">1.</label>
                <textarea
                  class="dflex tarea form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
                <div className="dflex">
                  <img className="editimgsize" src={BlueEdit} alt="edit" />
                  <img className="deleteimgsize" src={RedDelete} alt="edit" />
                </div>
              </div>
              <div className="dflex">
                <label className="dflex nosize">2.</label>
                <textarea
                  class="dflex tarea form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
                <div className="dflex">
                  <img className="editimgsize" src={BlueEdit} alt="edit" />
                  <img className="deleteimgsize" src={RedDelete} alt="edit" />
                </div>
              </div>
              <div className="dflex">
                <label className="dflex nosize">3.</label>
                <textarea
                  class="dflex tarea form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
                <div className="dflex">
                  <img className="editimgsize" src={BlueEdit} alt="edit" />
                  <img className="deleteimgsize" src={RedDelete} alt="edit" />
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            header={
              <div>
                <img
                  className="iconsizectmm"
                  src={conversation}
                  alt="conversation"
                />
                <label className="ml-3">FAQ</label>
                <img className="updnimg" src={plus} />
              </div>
            }
            key="4"
          >
            {/* <p>Sample 4</p> */}
            <div>
              <div className="faqform">
                <div className="dflex">
                  <label className="nosize">1.</label>
                  <label className="que">Q:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Question 1"
                  />
                  <img className="editimgsize" src={BlueEdit} alt="edit" />
                  <img className="deleteimgsize" src={RedDelete} alt="edit" />
                </div>
                <div className="dflex">
                  <label className="dhide nosize">1.</label>
                  <label className="ans">A:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Answer"
                  />
                  <img
                    className="dhide editimgsize"
                    src={BlueEdit}
                    alt="edit"
                  />
                  <img
                    className="dhide deleteimgsize"
                    src={RedDelete}
                    alt="edit"
                  />
                </div>
                <div className="dflex">
                  <label className="nosize">2.</label>
                  <label className="que">Q:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Question 2"
                  />
                  <img className="editimgsize" src={BlueEdit} alt="edit" />
                  <img className="deleteimgsize" src={RedDelete} alt="edit" />
                </div>
                <div className="dflex">
                  <label className="dhide nosize">2.</label>
                  <label className="ans">A:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Answer"
                  />
                  <img
                    className="dhide editimgsize"
                    src={BlueEdit}
                    alt="edit"
                  />
                  <img
                    className="dhide deleteimgsize"
                    src={RedDelete}
                    alt="edit"
                  />
                </div>
                <div className="dflex">
                  <label className="nosize">3.</label>
                  <label className="que">Q:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Question 3"
                  />
                  <img className="editimgsize" src={BlueEdit} alt="edit" />
                  <img className="deleteimgsize" src={RedDelete} alt="edit" />
                </div>
                <div className="dflex">
                  <label className="dhide nosize">3.</label>
                  <label className="ans">A:</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Answer"
                  />
                  <img
                    className="dhide editimgsize"
                    src={BlueEdit}
                    alt="edit"
                  />
                  <img
                    className="dhide deleteimgsize"
                    src={RedDelete}
                    alt="edit"
                  />
                </div>
              </div>
            </div>
          </Panel>
          <Panel
            header={
              <div>
                <img className="iconsizectmm" src={insurance} />
                <label className="ml-3">Privacy Policy</label>
                <img className="updnimg" src={plus} />
              </div>
            }
            key="5"
          >
            <p>Sample 5</p>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default contentManagement;
