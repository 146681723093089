import React, { Component } from "react";
import { Table, Popover } from "antd";
import Modal from "react-responsive-modal";
import CSV from "./../../../assets/Images/csv.png";
import InfoIcon from "./../../../assets/Images/Infoblue.png";
import RedDelete from "./../../../assets/Images/delete.png";
import BlueEdit from "./../../../assets/Images/editt.png";
import NotifiBl from "./../../../assets/Images/NotiBl.png";
import EmailBl from "./../../../assets/Images/emailBl.png";
import CloseIcon from "./../../../assets/Images/CloseWhBold.png";
import config from "./../../../helpers/config";
import axios from "axios";
import { authHeader } from "./../../../helpers/authHeader";
import { CSVLink } from "react-csv";
import Down from "./../../../assets/Images/download.png";

class alertTemplateManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addAlert: false,
      addContent: false,
      alertData: [],
      alertTypes: [],
      alertId: 0,
      alertName: "",
      status: false,
      isAdminByEmail: false,
      isCustomerByEmail: false,
      isMerchantByEmail: false,
      isAdminByNotification: false,
      isCustomerByNotification: false,
      isMerchantByNotification: false,
      emailContent1: "",
      emailContent2: "",
      emailContent3: "",
      notificationContent1: "",
      notificationContent2: "",
      notificationContent3: "",
      editAlert: false,
      editContent: false,
      editAlertName: "",
      editStatus: false,
      isEditAdminByEmail: false,
      isEditCustomerByEmail: false,
      isEditMerchantByEmail: false,
      isEditAdminByNotification: false,
      isEditCustomerByNotification: false,
      isEditMerchantByNotification: false,
      editEmailContent1: "",
      editEmailContent2: "",
      editEmailContent3: "",
      editNotificationContent1: "",
      editNotificationContent2: "",
      editNotificationContent3: "",
      touched: false,
      alertCSVData: [],
      mobileView: false,
    };
  }

  componentDidMount() {
    this.handleGetAlertTemplateList();
    this.handleGetAlertTypes();
    if (window.screen.width > 768) {
      this.setState({
        mobileView: false,
      });
    } else {
      this.setState({
        mobileView: true,
      });
    }
  }

  handleAddAlertOpen() {
    this.setState({ addAlert: true });
  }
  handleAddAlertClose() {
    this.setState({ addAlert: false, touched: false });
  }
  handleAddContentOpen() {
    const { alertName } = this.state;

    if (!this.state.touched)
      this.setState({
        touched: true,
      });

    if (!alertName) return;

    this.setState({ addContent: true, addAlert: false });
  }
  handleAddContentClose() {
    this.setState({ addContent: false, touched: false });
  }

  handleEditAlertOpen(item) {
    const { editAlertName } = this.state;

    this.setState({
      editAlert: true,
      alertId: item.alertID,
      editAlertName: item.alertTypeName,
      editStatus: item.isAlertActive === "Active" ? true : false,
      isEditAdminByEmail: item.alertContent[0].isEmailAdmin,
      isEditCustomerByEmail: item.alertContent[0].isEmailCustomer,
      isEditMerchantByEmail: item.alertContent[0].isEmailMerchant,
      editEmailContent: item.alertContent[0].mailContent,
      isEditAdminByNotification: item.alertContent[1].isNotificationAdmin,
      isEditCustomerByNotification: item.alertContent[1].isNotificationCustomer,
      isEditMerchantByNotification: item.alertContent[1].isNotificationMerchant,
      editNotificationContent: item.alertContent[1].notificationContent,
      touched: true,
    });
  }

  handleEditContentOpen() {
    this.setState({ editContent: true, editAlert: false, touched: true });
  }

  handleEditAlertClose() {
    this.setState({ editAlert: false, touched: false });
  }

  handleEditContentClose() {
    this.setState({ editContent: false, touched: false });
  }

  handleAlertTemplateSearch(e) {
    debugger;

    this.handleGetAlertTemplateList(e.target.value);
  }

  handleGetAlertTemplateList(search) {
    debugger;
    let self = this;
    var alertCSV = [];
    axios({
      method: "get",
      url: config.apiUrl + "Alert/GetAlertList",
      headers: authHeader(),
      params: {
        searchBy: search,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          data.map((item, i) =>
            alertCSV.push({
              alertTypeName: item.alertTypeName,
              cMode:
                (item.isByNotification === true ? "Notification" : "") +
                "," +
                (item.isByEmail === true ? "Email" : ""),
              createdBy: item.createdBy,
              isAlertActive: item.isAlertActive,
            })
          );
          self.setState({
            alertData: data,
            alertCSVData: alertCSV,
          });
        } else {
          self.setState({
            alertData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleGetAlertTypes() {
    let self = this;

    axios({
      method: "get",
      url: config.apiUrl + "Alert/GetAlertName",
      headers: authHeader(),
      params: {
        alertId: 0,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            alertTypes: data,
          });
        } else {
          self.setState({
            alertTypes: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleAlertTemplateSubmit() {
    debugger;
    let self = this;
    //var notification=true;
    // if(this.state.isEditCustomerByNotification===true && this.state.notificationContent1===""){
    //    notification=false;
    // }
    const { alertName, notificationContent, emailContent } = this.state;

    if (!this.state.touched)
      this.setState({
        touched: true,
      });

    if (!(alertName && notificationContent && emailContent)) return;

    var CommunicationModeDetails = [];
    CommunicationModeDetails = [
      {
        Communication_Mode: 240,
        IsAdmin: this.state.isAdminByEmail,
        IsCustomer: this.state.isCustomerByEmail,
        IsMerchant: this.state.isMerchantByEmail,
        Content: this.state.emailContent,
      },
      {
        Communication_Mode: 241,
        IsAdmin: this.state.isAdminByNotification,
        IsCustomer: this.state.isCustomerByNotification,
        IsMerchant: this.state.isMerchantByNotification,
        Content: this.state.notificationContent,
      },
    ];
    axios({
      method: "post",
      url: config.apiUrl + "Alert/CreateAlert",
      headers: authHeader(),
      data: {
        AlertName: this.state.alertName,
        IsAlertActive: this.state.status,
        CommunicationModeDetails: CommunicationModeDetails,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        if (status === "Success") {
          self.setState({
            addAlert: false,
            addContent: false,
          });
          self.handleGetAlertTemplateList();
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleAlertTemplateDelete(alertId) {
    debugger;
    let self = this;

    axios({
      method: "delete",
      url: config.apiUrl + "Alert/DeleteAlert",
      headers: authHeader(),
      params: {
        alertID: alertId,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        if (status === "Success") {
          self.handleGetAlertTemplateList();
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleAlertTemplateUpdate() {
    debugger;
    let self = this;

    const {
      editAlertName,
      editNotificationContent,
      editEmailContent,
    } = this.state;

    if (!(editAlertName && editNotificationContent && editEmailContent)) return;

    var CommunicationModeDetails = [];
    CommunicationModeDetails = [
      {
        Communication_Mode: 240,
        IsAdmin: this.state.isEditAdminByEmail,
        IsCustomer: this.state.isEditCustomerByEmail,
        IsMerchant: this.state.isEditMerchantByEmail,
        Content: this.state.editEmailContent,
      },
      {
        Communication_Mode: 241,
        IsAdmin: this.state.isEditAdminByNotification,
        IsCustomer: this.state.isEditCustomerByNotification,
        IsMerchant: this.state.isEditMerchantByNotification,
        Content: this.state.editNotificationContent,
      },
    ];
    axios({
      method: "put",
      url: config.apiUrl + "Alert/ModifyAlert",
      headers: authHeader(),
      data: {
        AlertID: this.state.alertId,
        AlertName: this.state.editAlertName,
        IsAlertActive: this.state.editStatus,
        CommunicationModeDetails: CommunicationModeDetails,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        if (status === "Success") {
          self.setState({
            editAlert: false,
            editContent: false,
          });
          self.handleGetAlertTemplateList();
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  render() {
    const headers = [
      { label: "Alert Type", key: "alertTypeName" },
      { label: "Communication Mode", key: "cMode" },
      { label: "Created By", key: "createdBy" },
      { label: "Status", key: "isAlertActive" },
    ];

    const columns = [
      // {
      //     title: 'No.',
      //     dataIndex: 'no',
      //     key: 'no',
      // },
      {
        title: "Alert Type",
        dataIndex: "alertTypeName",
        key: "alertTypeName",
      },
      {
        title: "Communication Mode",
        dataIndex: "cMode",
        key: "cMode",
        className: "mob-none",
        render: (row, item) => {
          return (
            <div className="CommNoti">
              {(() => {
                if (
                  item.isByNotification === true &&
                  item.isByEmail === false
                ) {
                  return <img src={NotifiBl} alt="Notification" />;
                } else if (
                  item.isByEmail === true &&
                  item.isByNotification === false
                ) {
                  return <img src={EmailBl} alt="Email" />;
                } else if (
                  item.isByEmail === true &&
                  item.isByNotification === true
                ) {
                  return (
                    <>
                      <img src={NotifiBl} alt="Notification" />
                      <img src={EmailBl} alt="Email" />
                    </>
                  );
                }
              })()}
            </div>
          );
        },
      },
      {
        title: "Created By",
        dataIndex: "createdBy",
        key: "createdBy",
        className: "mob-none",
        render: (row, item) => {
          return (
            <div className="amazontext">
              <label>{item.createdBy}</label>
              <Popover
                content={
                  <div className="userpopover">
                    <div className="subsc">
                      <label>Created By</label>
                      <label>{item.createdBy}</label>
                    </div>
                    <div className="subsc">
                      <label>Created On</label>
                      <label>{item.createdDate}</label>
                    </div>
                    <div className="subsc">
                      <label>Modified By</label>
                      <label>{item.modifiedBy}</label>
                    </div>
                    <div className="subsc">
                      <label>Modified On</label>
                      <label>{item.modifiedDate}</label>
                    </div>
                  </div>
                }
                placement="bottom"
                trigger="click"
              >
                <img src={InfoIcon} alt="InfoIcon" />
              </Popover>
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (row, item) => {
          return (
            <div className="status">
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={item.isAlertActive == "Active" ? true : false}
                    disabled
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "Action",
        render: (row, item) => {
          return (
            <div className="action">
              <div className="editdele">
                <img
                  src={BlueEdit}
                  alt="Edit"
                  onClick={this.handleEditAlertOpen.bind(this, item)}
                />
                <Popover
                  content={
                    <div className="deletepopover text-center">
                      <h3>Are you sure to delete ?</h3>
                      <button className="delete">Cancel</button>
                      <button
                        className="delete"
                        onClick={this.handleAlertTemplateDelete.bind(
                          this,
                          item.alertID
                        )}
                      >
                        Delete
                      </button>
                    </div>
                  }
                  placement="bottomRight"
                  trigger="click"
                >
                  <img src={RedDelete} alt="Delete" />
                </Popover>
              </div>
            </div>
          );
        },
      },
    ];

    return (
      <div className="alerttemplate">
        <h3 className="Usermana">Alert Template Management</h3>
        <div className="exfilter">
          <input
            type="text"
            placeholder="Search Anything"
            onChange={this.handleAlertTemplateSearch.bind(this)}
          />
          <CSVLink
            data={this.state.alertCSVData}
            headers={headers}
            filename={"Alert Template Management.csv"}
            className="csv"
          >
            <img src={CSV} alt="Export" />
            Export to CSV
          </CSVLink>
          <label className="add" onClick={this.handleAddAlertOpen.bind(this)}>
            Add New
          </label>
        </div>
        <div className="alerttable">
          <Table
            columns={columns}
            expandedRowRender={(row) => {
              return (
                <React.Fragment>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Alert Type:</label>
                        <label className="expandemailtext">{row.alertTypeName}</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Communication Mode:</label>
                        <label className="expandemailtext">{row.cMode}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Created By:</label>
                        <div className="amazontext">
                          <label className="expandemailtext">
                            {row.createBy}
                          </label>
                          <Popover
                            content={
                              <div className="userpopover">
                                <div className="subsc">
                                  <label>Created By</label>
                                  <label>{row.createBy}</label>
                                </div>
                                <div className="subsc">
                                  <label>Created On</label>
                                  <label>{row.createDate}</label>
                                </div>
                                <div className="subsc">
                                  <label>Modified By</label>
                                  <label>{row.updateBy}</label>
                                </div>
                                <div className="subsc">
                                  <label>Modified On</label>
                                  <label>{row.updateDate}</label>
                                </div>
                              </div>
                            }
                            placement="bottom"
                            trigger="click"
                          >
                            <img src={InfoIcon} alt="InfoIcon" />
                          </Popover>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Status:</label>
                        <label className="expandemailtext">
                          {row.isActive ? "Active" : "Inactive"}
                        </label>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }}
            expandIcon={ ({ expanded, onExpand, record }) =>
            expanded ? (
              <div className="expandown1">
                <img src={Down} onClick={e => onExpand(record, e)} />
              </div>
            ) : (
              <div className="expandown">
                <img src={Down} onClick={e => onExpand(record, e)} />
              </div>
            )}
            expandIconColumnIndex={this.state.mobileView ? 5 : -1}
            expandIconAsCell={false}
            dataSource={this.state.alertData}
            pagination={{position:["bottomCenter"]}}
          />
        </div>
        {/* Add Modal */}
        <Modal
          open={this.state.addAlert}
          onClose={this.handleAddAlertClose.bind(this)}
          modalId="alerttempmodal"
          overlayId="overlay"
        >
          <div className="backtext">
            <h3 className="eduser">Add Alert Template</h3>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              className="closeicon"
              onClick={this.handleAddAlertClose.bind(this)}
            />
          </div>
          <div className="edituser">
            <div className="row">
              <div className="col-12 col-md-6">
                <label>Alert Type</label>
                <select
                  name="alertName"
                  value={this.state.alertName}
                  onChange={this.handleOnChange.bind(this)}
                >
                  <option value="">Select Alert Type</option>
                  {this.state.alertTypes !== null &&
                    this.state.alertTypes.map((item, i) => (
                      <option value={item.alertName}>{item.alertName}</option>
                    ))}
                </select>
                {this.state.alertName.length === 0 && this.state.touched && (
                  <span className="Error">Required.</span>
                )}
              </div>
              <div className="col-12 col-md-6">
                <label>Status</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={this.state.status}
                    onClick={() =>
                      this.setState({ status: !this.state.status })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <label className="commode">Communication Mode</label>
            <div className="row">
              <div className="col-12 col-md-12">
                <label>Email</label>
                <div className="emailcheck">
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check1"
                      checked={this.state.isCustomerByEmail}
                      onClick={() =>
                        this.setState({
                          isCustomerByEmail: !this.state.isCustomerByEmail,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check1">
                      User
                    </label>
                  </div>
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check2"
                      checked={this.state.isMerchantByEmail}
                      onClick={() =>
                        this.setState({
                          isMerchantByEmail: !this.state.isMerchantByEmail,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check2">
                      Merchant
                    </label>
                  </div>
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check3"
                      checked={this.state.isAdminByEmail}
                      onClick={() =>
                        this.setState({
                          isAdminByEmail: !this.state.isAdminByEmail,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check3">
                      Admin
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <label>Notification</label>
                <div className="emailcheck">
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check4"
                      checked={this.state.isCustomerByNotification}
                      onClick={() =>
                        this.setState({
                          isCustomerByNotification: !this.state
                            .isCustomerByNotification,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check4">
                      User
                    </label>
                  </div>
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check5"
                      checked={this.state.isMerchantByNotification}
                      onClick={() =>
                        this.setState({
                          isMerchantByNotification: !this.state
                            .isMerchantByNotification,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check5">
                      Merchant
                    </label>
                  </div>
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check6"
                      checked={this.state.isAdminByNotification}
                      onClick={() =>
                        this.setState({
                          isAdminByNotification: !this.state
                            .isAdminByNotification,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check6">
                      Admin
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="Editbtn">
              <button
                className="btn adcontent"
                onClick={this.handleAddContentOpen.bind(this)}
              >
                Add Content
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.addContent}
          onClose={this.handleAddContentClose.bind(this)}
          modalId="contentmodal"
          overlayId="overlay"
        >
          <div className="backtext">
            <h3 className="eduser">Add Alert Template</h3>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              className="closeicon"
              onClick={this.handleAddContentClose.bind(this)}
            />
          </div>
          <div className="edituser">
            <div className="secondtab mb-4">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#noti-Tab"
                    role="tab"
                    aria-controls="noti-Tab"
                    aria-selected="true"
                  >
                    <img src={NotifiBl} className="emailimg" />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#email-Tab"
                    role="tab"
                    aria-controls="email-Tab"
                    aria-selected="false"
                  >
                    <img src={EmailBl} className="emailimg" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div
                class="tab-pane fade active show"
                id="noti-Tab"
                role="tabpanel"
                aria-labelledby="noti-Tab"
              >
                <div className="secondtab">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#user-Tab"
                        role="tab"
                        aria-controls="user-Tab"
                        aria-selected="true"
                      >
                        User
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#merchant-Tab"
                        role="tab"
                        aria-controls="merchant-Tab"
                        aria-selected="false"
                      >
                        Merchant
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#admin-Tab"
                        role="tab"
                        aria-controls="admin-Tab"
                        aria-selected="false"
                      >
                        Admin
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    class="tab-pane fade active show"
                    id="user-Tab"
                    role="tabpanel"
                    aria-labelledby="user-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="notificationContent"
                      value={this.state.notificationContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {/* {this.state.isCustomerByNotification === true && (
                                            this.state.notificationContent.length === 0 &&
                                            this.state.touched && (
                                                <span className="Error">Required.</span>
                                            )
                                        )} */}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="merchant-Tab"
                    role="tabpanel"
                    aria-labelledby="merchant-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="notificationContent"
                      value={this.state.notificationContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {/* {this.state.isMerchantByNotification === true && (
                                            this.state.notificationContent.length === 0 &&
                                            this.state.touched && (
                                                <span className="Error">Required.</span>
                                            )
                                        )} */}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="admin-Tab"
                    role="tabpanel"
                    aria-labelledby="admin-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="notificationContent"
                      value={this.state.notificationContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {/* {this.state.isAdminByNotification === true && (
                                            this.state.notificationContent.length === 0 &&
                                            this.state.touched && (
                                                <span className="Error">Required.</span>
                                            )
                                        )} */}
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="email-Tab"
                role="tabpanel"
                aria-labelledby="email-Tab"
              >
                <div className="secondtab">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#user-Tab"
                        role="tab"
                        aria-controls="user-Tab"
                        aria-selected="true"
                      >
                        User
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#merchant-Tab"
                        role="tab"
                        aria-controls="merchant-Tab"
                        aria-selected="false"
                      >
                        Merchant
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#admin-Tab"
                        role="tab"
                        aria-controls="admin-Tab"
                        aria-selected="false"
                      >
                        Admin
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    class="tab-pane fade active show"
                    id="user-Tab"
                    role="tabpanel"
                    aria-labelledby="user-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="emailContent"
                      value={this.state.emailContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {/* {this.state.isCustomerByEmail === true && (
                                            this.state.emailContent.length === 0 &&
                                            this.state.touched && (
                                                <span className="Error">Required.</span>
                                            )
                                        )} */}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="merchant-Tab"
                    role="tabpanel"
                    aria-labelledby="merchant-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="emailContent"
                      value={this.state.emailContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {/* {this.state.isMerchantByEmail === true && (
                                            this.state.emailContent.length === 0 &&
                                            this.state.touched && (
                                                <span className="Error">Required.</span>
                                            )
                                        )} */}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="admin-Tab"
                    role="tabpanel"
                    aria-labelledby="admin-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="emailContent"
                      value={this.state.emailContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {/* {this.state.isAdminByEmail === true && (
                                            this.state.emailContent.length === 0 &&
                                            this.state.touched && (
                                                <span className="Error">Required.</span>
                                            )
                                        )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="Editbtn">
              <button
                className="btn"
                onClick={this.handleAlertTemplateSubmit.bind(this)}
              >
                Add
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.editAlert}
          onClose={this.handleEditAlertClose.bind(this)}
          modalId="alerttempmodal"
          overlayId="overlay"
        >
          <div className="backtext">
            <h3 className="eduser">Edit Alert Template</h3>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              className="closeicon"
              onClick={this.handleEditAlertClose.bind(this)}
            />
          </div>
          <div className="edituser">
            <div className="row">
              <div className="col-12 col-md-6">
                <label>Alert Type</label>
                <select
                  name="editAlertName"
                  value={this.state.editAlertName}
                  onChange={this.handleOnChange.bind(this)}
                >
                  <option>Select Alert Type</option>
                  {this.state.alertTypes !== null &&
                    this.state.alertTypes.map((item, i) => (
                      <option value={item.alertName}>{item.alertName}</option>
                    ))}
                </select>
                {this.state.editAlertName.length === 0 &&
                  this.state.touched && (
                    <span className="Error">Required.</span>
                  )}
              </div>
              <div className="col-12 col-md-6">
                <label>Status</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={this.state.editStatus}
                    onClick={() =>
                      this.setState({ editStatus: !this.state.editStatus })
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <label className="commode">Communication Mode</label>
            <div className="row">
              <div className="col-12 col-md-12">
                <label>Email</label>
                <div className="emailcheck">
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check1"
                      checked={this.state.isEditCustomerByEmail}
                      onClick={() =>
                        this.setState({
                          isEditCustomerByEmail: !this.state
                            .isEditCustomerByEmail,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check1">
                      User
                    </label>
                  </div>
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check2"
                      checked={this.state.isEditMerchantByEmail}
                      onClick={() =>
                        this.setState({
                          isEditMerchantByEmail: !this.state
                            .isEditMerchantByEmail,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check2">
                      Merchant
                    </label>
                  </div>
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check3"
                      checked={this.state.isEditAdminByEmail}
                      onClick={() =>
                        this.setState({
                          isEditAdminByEmail: !this.state.isEditAdminByEmail,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check3">
                      Admin
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <label>Notification</label>
                <div className="emailcheck">
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check4"
                      checked={this.state.isEditCustomerByNotification}
                      onClick={() =>
                        this.setState({
                          isEditCustomerByNotification: !this.state
                            .isEditCustomerByNotification,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check4">
                      User
                    </label>
                  </div>
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check5"
                      checked={this.state.isEditMerchantByNotification}
                      onClick={() =>
                        this.setState({
                          isEditMerchantByNotification: !this.state
                            .isEditMerchantByNotification,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check5">
                      Merchant
                    </label>
                  </div>
                  <div className="em">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="Check6"
                      checked={this.state.isEditAdminByNotification}
                      onClick={() =>
                        this.setState({
                          isEditAdminByNotification: !this.state
                            .isEditAdminByNotification,
                        })
                      }
                    />
                    <label className="form-check-label" for="Check6">
                      Admin
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="Editbtn">
              <button
                className="btn"
                onClick={this.handleEditContentOpen.bind(this)}
              >
                Add Content
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.editContent}
          onClose={this.handleEditContentClose.bind(this)}
          modalId="contentmodal"
          overlayId="overlay"
        >
          <div className="backtext">
            <h3 className="eduser">Edit Alert Template</h3>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              className="closeicon"
              onClick={this.handleEditContentClose.bind(this)}
            />
          </div>
          <div className="edituser">
            <div className="secondtab mb-4">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#noti-Tab"
                    role="tab"
                    aria-controls="noti-Tab"
                    aria-selected="true"
                  >
                    <img src={NotifiBl} className="emailimg" />
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#email-Tab"
                    role="tab"
                    aria-controls="email-Tab"
                    aria-selected="false"
                  >
                    <img src={EmailBl} className="emailimg" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div
                class="tab-pane fade active show"
                id="noti-Tab"
                role="tabpanel"
                aria-labelledby="noti-Tab"
              >
                <div className="secondtab">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#user-Tab"
                        role="tab"
                        aria-controls="user-Tab"
                        aria-selected="true"
                      >
                        User
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#merchant-Tab"
                        role="tab"
                        aria-controls="merchant-Tab"
                        aria-selected="false"
                      >
                        Merchant
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#admin-Tab"
                        role="tab"
                        aria-controls="admin-Tab"
                        aria-selected="false"
                      >
                        Admin
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    class="tab-pane fade active show"
                    id="user-Tab"
                    role="tabpanel"
                    aria-labelledby="user-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="editNotificationContent"
                      value={this.state.editNotificationContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {this.state.isEditCustomerByNotification === true &&
                      this.state.editNotificationContent.length === 0 &&
                      this.state.touched && (
                        <span className="Error">Required.</span>
                      )}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="merchant-Tab"
                    role="tabpanel"
                    aria-labelledby="merchant-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="editNotificationContent"
                      value={this.state.editNotificationContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {this.state.isEditMerchantByNotification === true &&
                      this.state.editNotificationContent.length === 0 &&
                      this.state.touched && (
                        <span className="Error">Required.</span>
                      )}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="admin-Tab"
                    role="tabpanel"
                    aria-labelledby="admin-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="editNotificationContent"
                      value={this.state.editNotificationContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {this.state.isEditAdminByNotification === true &&
                      this.state.editNotificationContent.length === 0 &&
                      this.state.touched && (
                        <span className="Error">Required.</span>
                      )}
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="email-Tab"
                role="tabpanel"
                aria-labelledby="email-Tab"
              >
                <div className="secondtab">
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#user-Tab"
                        role="tab"
                        aria-controls="user-Tab"
                        aria-selected="true"
                      >
                        User
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#merchant-Tab"
                        role="tab"
                        aria-controls="merchant-Tab"
                        aria-selected="false"
                      >
                        Merchant
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#admin-Tab"
                        role="tab"
                        aria-controls="admin-Tab"
                        aria-selected="false"
                      >
                        Admin
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div
                    class="tab-pane fade active show"
                    id="user-Tab"
                    role="tabpanel"
                    aria-labelledby="user-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="editEmailContent"
                      value={this.state.editEmailContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {this.state.isEditCustomerByEmail === true &&
                      this.state.editEmailContent.length === 0 &&
                      this.state.touched && (
                        <span className="Error">Required.</span>
                      )}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="merchant-Tab"
                    role="tabpanel"
                    aria-labelledby="merchant-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="editEmailContent"
                      value={this.state.editEmailContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {this.state.isEditMerchantByEmail === true &&
                      this.state.editEmailContent.length === 0 &&
                      this.state.touched && (
                        <span className="Error">Required.</span>
                      )}
                  </div>
                  <div
                    class="tab-pane fade"
                    id="admin-Tab"
                    role="tabpanel"
                    aria-labelledby="admin-Tab"
                  >
                    <label className="editorte">Text Editor</label>
                    <textarea
                      className="emailarea"
                      name="editEmailContent"
                      value={this.state.editEmailContent}
                      onChange={this.handleOnChange.bind(this)}
                    ></textarea>
                    {this.state.isEditAdminByEmail === true &&
                      this.state.editEmailContent.length === 0 &&
                      this.state.touched && (
                        <span className="Error">Required.</span>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="Editbtn">
              <button
                className="btn"
                onClick={this.handleAlertTemplateUpdate.bind(this)}
              >
                Update
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default alertTemplateManagement;
