import React, { Component } from "react";
import Total from "./../../assets/Images/total.png";
import InfoIcon from "./../../assets/Images/Infoblue.png";
import { Table, Popover } from "antd";
import config from "./../../helpers/config";
import axios from "axios";
import { authHeader } from "./../../helpers/authHeader";
import Down from "./../../assets/Images/download.png";

class merchantDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentMonthSales: 0,
      dailySales: 0,
      numberofUsersCount: 0,
      totalOrder: 0,
      transactionHistoryData: [],
      mobileView: false,
    };
  }

  componentDidMount() {
    this.handleGetMerchantDashboard();
    this.handleGetTransactionHistoryList();
    if (window.screen.width > 768) {
      this.setState({
        mobileView: false,
      });
    } else {
      this.setState({
        mobileView: true,
      });
    }
  }

  handleGetMerchantDashboard() {
    let self = this;

    axios({
      method: "get",
      url: config.apiUrl + "MerchantDashboard/DashboardCarts",
      headers: authHeader(),
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            currentMonthSales: data.currentMonthSales,
            dailySales: data.dailySales,
            numberofUsersCount: data.numberofUsersCount,
            totalOrder: data.totalOrder,
          });
        } else {
          self.setState({
            currentMonthSales: 0,
            dailySales: 0,
            numberofUsersCount: 0,
            totalOrder: 0,
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleGetTransactionHistoryList() {
    let self = this;

    axios({
      method: "post",
      url: config.apiUrl + "MerchantTransaction/TransactionHistory",
      headers: authHeader(),
      data: {
        OrderId: "",
        StartDate: null,
        EndDate: null,
        TransactionAmountFrom: 0,
        TransactionAmountTo: 0,
        EmailId: "",
        ContactNumber: "",
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            transactionHistoryData: data,
          });
        } else {
          self.setState({
            transactionHistoryData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  render() {
    const merchantinfo = (
      <div className="merrefunfpopover">
        <div className="subsc">
          <label>Refund</label>
          <label>xyz Refund</label>
        </div>
        <div className="subsc">
          <label>Refund</label>
          <label>xyz Refund</label>
        </div>
        <div className="subsc">
          <label>Refund</label>
          <label>xyz Refund</label>
        </div>
      </div>
    );
    const DeleteUser = (
      <div className="deletepopover text-center">
        <h3>Are you sure to refund ?</h3>
        <button className="delete">Cancel</button>
        <button className="refundbt">Refund</button>
      </div>
    );
    const columns = [
      {
        title: "Date",
        dataIndex: "transactionDate",
        key: "transactionDate",
      },
      {
        title: "Order Id",
        dataIndex: "orderId",
        key: "orderId",
      },
      {
        title: "Transaction Id",
        dataIndex: "transactionID",
        key: "transactionID",
        className: "mob-none",
      },
      {
        title: "Transaction Value (AU$)",
        dataIndex: "amount",
        key: "amount",
        className: "mob-none",
      },
      {
        title: "Email",
        dataIndex: "emailId",
        key: "emailId",
        className: "mob-none",
      },
      {
        title: "Contact Number",
        dataIndex: "contactNumber",
        key: "contactNumber",
        className: "mob-none",
      },
      {
        title: "Payment Details",
        dataIndex: "customerCard",
        key: "customerCard",
        className: "mob-none",
      },
      {
        title: "Status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        render: (row, item) => {
          return (() => {
            if (item.paymentStatus.toLowerCase().trim() === "refunded") {
              return (
                <div className="amazontext">
                  <label className="refundtext">{item.paymentStatus}</label>
                  <Popover
                    content={
                      <div className="merrefunfpopover">
                        <div className="subsc">
                          <label>Refunded Date:</label>
                          <label>{item.refundDate}</label>
                        </div>
                      </div>
                    }
                    placement="bottom"
                    trigger="click"
                  >
                    <img src={InfoIcon} alt="InfoIcon" />
                  </Popover>
                </div>
              );
            } else if (item.paymentStatus.toLowerCase().trim() === "success") {
              return (
                <div className="amazontext">
                  <label className="success">{item.paymentStatus}</label>
                </div>
              );
            } else if (item.paymentStatus.toLowerCase().trim() === "failed") {
              return (
                <div className="amazontext">
                  <label className="failed">{item.paymentStatus}</label>
                </div>
              );
            } else if (item.paymentStatus.toLowerCase().trim() === "progress") {
              return (
                <div className="amazontext">
                  <label className="custom-link">{item.paymentStatus}</label>
                </div>
              );
            } else if (
              item.paymentStatus.toLowerCase().trim() === "refund init"
            ) {
              return (
                <div className="amazontext">
                  <label className="refundtext">{item.paymentStatus}</label>
                </div>
              );
            }
          })();
        },
      },
    ];

    return (
      <div>
        <div className="Merchantdashboard">
          <h3 className="dash">Dashboard</h3>
          <div className="row mt-4">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
              <div className="card back1">
                <div className="image">
                  <img src={Total} alt="Card1" />
                </div>
                <div className="text">
                  <label>{this.state.dailySales}</label>
                  <label>Daily Sales Using Instant Pay (AU$)</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
              <div className="card back2">
                <div className="image">
                  <img src={Total} alt="Card1" />
                </div>
                <div className="text">
                  <label>{this.state.currentMonthSales}</label>
                  <label>Current Month Sales (AU$)</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
              <div className="card back3">
                <div className="image">
                  <img src={Total} alt="Card1" />
                </div>
                <div className="text">
                  <label>{this.state.numberofUsersCount}</label>
                  <label>Number of Users Count</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
              <div className="card back4">
                <div className="image">
                  <img src={Total} alt="Card1" />
                </div>
                <div className="text">
                  <label>{this.state.totalOrder}</label>
                  <label>Total Orders Using Instant Pay)</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="Merchantdashtable">
                <Table
                  columns={columns}
                  expandedRowRender={(row) => {
                    return (
                      <React.Fragment>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Date:</label>
                              <label className="expandemailtext">{row.transactionDate}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Order Id:</label>
                              <label className="expandemailtext">{row.orderId}</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Transaction Id:</label>
                              <label className="expandemailtext">{row.transactionID}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Transaction Value (AU$):</label>
                              <label className="expandemailtext">{row.amount}</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Email:</label>
                              <label className="expandemailtext">{row.emailId}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Contact Number:</label>
                              <label className="expandemailtext">{row.contactNumber}</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Payment Details:</label>
                              <label className="expandemailtext">{row.customerCard}</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Status:</label>
                              <label className="expandemailtext">
                                {row.isActive ? "Active" : "Inactive"}
                              </label>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }}
                  expandIcon={ ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <div className="expandown1">
                      <img src={Down} onClick={e => onExpand(record, e)} />
                    </div>
                  ) : (
                    <div className="expandown">
                      <img src={Down} onClick={e => onExpand(record, e)} />
                    </div>
                  )}
                  expandIconColumnIndex={this.state.mobileView ? 8 : -1}
                  expandIconAsCell={false}
                  dataSource={this.state.transactionHistoryData}
                  pagination={{position:["bottomCenter"]}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default merchantDashboard;
