import React, { Component } from "react";
import CSV from "./../../../assets/Images/csv.png";
import { Table, Popover, Select } from "antd";
import InfoIcon from "./../../../assets/Images/Infoblue.png";
import RedDelete from "./../../../assets/Images/delete.png";
import BlueEdit from "./../../../assets/Images/editt.png";
import Modal from "react-responsive-modal";
import CloseIcon from "./../../../assets/Images/CloseWhBold.png";
import config from "./../../../helpers/config";
import axios from "axios";
import { authHeader } from "./../../../helpers/authHeader";
import { NotificationManager } from "react-notifications";
import { CSVLink } from "react-csv";
import Down from "./../../../assets/Images/download.png";

class rolesPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editRole: false,
      addRole: false,
      rolesPermissionsData: [],
      mappedModules: [],
      mappedSubModules: [],
      roleInfo: {
        name: "",
        modules: [],
        subModules: [],
        status: false,
      },
      touched: false,
      roleId: "",
      rolesPermissionsCSVData: [],
      mobileView: false,
    };
  }

  componentDidMount() {
    this.handleGetRolesPermissionsList();
    this.handleGetMappedModulesList();
    if (window.screen.width > 768) {
      this.setState({
        mobileView: false,
      });
    } else {
      this.setState({
        mobileView: true,
      });
    }
  }

  handleGetMappedModulesList() {
    let self = this;

    axios({
      method: "get",
      url: config.apiUrl + "Settings/ModuleList",
      headers: authHeader(),
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            mappedModules: data,
          });
        } else {
          self.setState({
            mappedModules: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleGetMappedSubModulesList() {
    debugger;
    if (this.state.roleInfo.modules.length > 0) {
      let self = this;
      debugger;

      axios({
        method: "get",
        url: config.apiUrl + "Settings/SubModuleList",
        headers: authHeader(),
        params: {
          moduleIDs: this.state.roleInfo.modules.join(","),
        },
      })
        .then(function (res) {
          debugger;
          let status = res.data.message;
          let data = res.data.responseData;
          if (status === "Success") {
            self.setState({
              mappedSubModules: data,
            });
          } else {
            self.setState({
              mappedSubModules: [],
            });
          }
        })
        .catch((data) => {
          console.log(data);
        });
    }
  }

  handleEditRole() {
    let self = this;
    debugger;

    const { name, modules, subModules } = this.state.roleInfo;

    if (!(name && modules && subModules)) return;

    axios({
      method: "put",
      url: config.apiUrl + "Settings/UpdateRoleAndPermission",
      headers: authHeader(),
      data: {
        RoleID: this.state.roleId,
        RoleName: this.state.roleInfo.name,
        SubmoduleIDs: this.state.roleInfo.subModules.join("|"),
        isActive: this.state.roleInfo.status,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        if (status === "Success") {
          NotificationManager.success("Role updated successfully.");
          self.handleGetRolesPermissionsList();
          self.handdleeditRoleClose();
        } else {
          NotificationManager.error("Role not updated.");
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleRolesPermissionSearch(e) {
    debugger;

    this.handleGetRolesPermissionsList(e.target.value);
  }

  handleGetRolesPermissionsList(search) {
    let self = this;
    var rolesPermissionCSV = [];

    axios({
      method: "get",
      url: config.apiUrl + "Settings/RolePermissionsList",
      headers: authHeader(),
      params: {
        searchBy: search,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          data.map((item, i) =>
            rolesPermissionCSV.push({
              roleName: item.roleName,
              mappedModuleName: item.mappedModuleName,
              mappedSubModuleName: item.mappedSubModuleName,
              createdBy: item.createdBy,
              status: item.status === true ? "Active" : "Inactive",
            })
          );
          self.setState({
            rolesPermissionsData: data,
            rolesPermissionsCSVData: rolesPermissionCSV,
          });
        } else {
          self.setState({
            rolesPermissionsData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleaddRoleOpen() {
    this.setState({
      addRole: true,
      roleInfo: {
        name: "",
        modules: [],
        subModules: [],
        status: false,
      },
      touched: false,
    });
  }
  handleaddRoleClose() {
    this.setState({
      addRole: false,
      roleInfo: {
        name: "",
        modules: [],
        subModules: [],
        status: false,
      },
      roleId: "",
      touched: false,
    });
  }
  handdleeditRoleOpen(role) {
    debugger;
    let roleInfo = { ...this.state.roleInfo };
    roleInfo.name = role.roleName;
    roleInfo.modules = role.mappedModuleIDs.split(",");
    roleInfo.subModules = [];
    roleInfo.status = role.status;
    this.setState(
      {
        editRole: true,
        roleInfo,
        roleId: role.roleID,
      },
      function () {
        this.handleGetMappedSubModulesList();
        roleInfo.subModules = role.mappedModuleSubModuleIDs.split("|");
        this.setState({
          roleInfo,
          touched: true,
        });
      }
    );
  }
  handdleeditRoleClose() {
    this.setState({
      editRole: false,
      roleInfo: {
        name: "",
        modules: [],
        subModules: [],
        status: false,
      },
      roleId: "",
      touched: false,
    });
  }

  handleAddRole() {
    let self = this;
    debugger;
    const { name, modules, subModules } = this.state.roleInfo;

    if (!this.state.touched)
      this.setState({
        touched: true,
      });

    if (!(name && modules && subModules)) return;

    axios({
      method: "post",
      url: config.apiUrl + "Settings/CreateRoleAndPermission",
      headers: authHeader(),
      data: {
        RoleName: this.state.roleInfo.name,
        SubmoduleIDs: this.state.roleInfo.subModules.join("|"),
        isActive: this.state.roleInfo.status,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        if (status === "Success") {
          NotificationManager.success("Role created successfully.");
          self.handleGetRolesPermissionsList();
          self.setState({
            addRole: false,
            roleInfo: {
              name: "",
              modules: [],
              subModules: [],
              status: false,
            },
          });
        } else {
          NotificationManager.error("Role not created.");
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleAddModules = (e) => {
    debugger;
    let roleInfo = { ...this.state.roleInfo };
    // let abc = [];

    roleInfo.modules = e;
    // roleInfo.modules.forEach(myFunction);
    // function myFunction(item1, index) {
    //   debugger;
    //   abc = roleInfo.subModules.filter((item) => {
    //     debugger;
    //     return item.split(",")[0] === item1;
    //   });
    // }
    roleInfo.subModules = [];
    // roleInfo.subModules = abc;
    this.setState({
      roleInfo,
      mappedSubModules: [],
    });
  };

  handleAddSubModules = (e) => {
    debugger;
    let roleInfo = { ...this.state.roleInfo };
    roleInfo.subModules = e;
    this.setState({
      roleInfo,
    });
  };

  handleInputOnchange = (e) => {
    debugger;
    let roleInfo = { ...this.state.roleInfo };
    if (e.target.name === "status") {
      roleInfo[e.target.name] = e.target.checked;
    } else {
      roleInfo[e.target.name] = e.target.value;
    }
    this.setState({
      roleInfo,
    });
  };

  handleDeleteRolesPermissions(roleID) {
    let self = this;

    axios({
      method: "delete",
      url: config.apiUrl + "Settings/DeleteRolePermission",
      headers: authHeader(),
      params: {
        roleID,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        if (status === "Success") {
          NotificationManager.success("Record deleted successfully.");
          self.handleGetRolesPermissionsList();
        } else {
          NotificationManager.error("Record not deleted.");
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  render() {
    const { Option } = Select;
    const headers = [
      { label: "Role", key: "roleName" },
      { label: "Mapped Modules", key: "mappedModuleName" },
      { label: "Mapped Sub Modules", key: "mappedSubModuleName" },
      { label: "Created By", key: "createdBy" },
      { label: "Status", key: "status" },
    ];
    const columns = [
      {
        title: "Role",
        dataIndex: "roleName",
        key: "roleName",
      },
      {
        title: "Mapped Modules",
        dataIndex: "mappedModuleName",
        key: "mappedModuleName",
        className: "mob-none",
      },
      {
        title: "Mapped Sub Modules",
        dataIndex: "mappedSubModuleName",
        key: "mappedSubModuleName",
        className: "mob-none",
      },
      {
        title: "Created By",
        dataIndex: "createdBy",
        key: "createdBy",
        className: "mob-none",
        render: (row, item) => {
          return (
            <div className="amazontext">
              <label>{item.createdBy}</label>
              <Popover
                content={
                  <div className="userpopover">
                    <div className="subsc">
                      <label>Created By</label>
                      <label>{item.createdBy}</label>
                    </div>
                    <div className="subsc">
                      <label>Created On</label>
                      <label>{item.createddate}</label>
                    </div>
                    <div className="subsc">
                      <label>Modified By</label>
                      <label>{item.updatedBy}</label>
                    </div>
                    <div className="subsc">
                      <label>Modified On</label>
                      <label>{item.updatedDate}</label>
                    </div>
                  </div>
                }
                placement="bottom"
                trigger="hover"
              >
                <img src={InfoIcon} alt="InfoIcon" />
              </Popover>
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (row, item) => {
          return (
            <div className="status">
              <div>
                <label className="switch">
                  <input type="checkbox" checked={item.status} disabled />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          );
        },
      },
      {
        title: "Actions",
        dataIndex: "action",
        key: "Action",
        render: (row, item) => {
          return (
            <div className="action">
              <div className="editdele">
                <img
                  src={BlueEdit}
                  alt="Edit"
                  onClick={this.handdleeditRoleOpen.bind(this, item)}
                />
                <Popover
                  content={
                    <div className="deletepopover text-center">
                      <h3>Are you sure to delete ?</h3>
                      <button className="delete">Cancel</button>
                      <button
                        className="delete"
                        onClick={this.handleDeleteRolesPermissions.bind(
                          this,
                          item.roleID
                        )}
                      >
                        Delete
                      </button>
                    </div>
                  }
                  placement="bottomRight"
                  trigger="click"
                >
                  <img src={RedDelete} alt="Delete" />
                </Popover>
              </div>
            </div>
          );
        },
      },
    ];

    return (
      <div className="roles">
        <h3 className="Usermana">Roles & Permissions</h3>
        <div className="exfilter">
          <input
            type="text"
            placeholder="Search Anything"
            onChange={this.handleRolesPermissionSearch.bind(this)}
          />
          <CSVLink
            data={this.state.rolesPermissionsCSVData}
            headers={headers}
            filename={"Roles & Permissions.csv"}
            className="csv"
          >
            <img src={CSV} alt="Export" />
            Export to CSV
          </CSVLink>
          <label className="add" onClick={this.handleaddRoleOpen.bind(this)}>
            Add New
          </label>
        </div>
        <div className="roletable">
          <Table
            columns={columns}
            expandedRowRender={(row) => {
              return (
                <React.Fragment>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Role:</label>
                        <label className="expandemailtext">{row.roleName}</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Mapped Modules:</label>
                        <label className="expandemailtext">{row.mappedModuleName}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Mapped Sub Modules:</label>
                        <label className="expandemailtext">{row.mappedSubModuleName}</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Created By:</label>
                        <div className="amazontext">
                          <label className="expandemailtext">
                            {row.createBy}
                          </label>
                          <Popover
                            content={
                              <div className="userpopover">
                                <div className="subsc">
                                  <label>Created By</label>
                                  <label>{row.createBy}</label>
                                </div>
                                <div className="subsc">
                                  <label>Created On</label>
                                  <label>{row.createDate}</label>
                                </div>
                                <div className="subsc">
                                  <label>Modified By</label>
                                  <label>{row.updateBy}</label>
                                </div>
                                <div className="subsc">
                                  <label>Modified On</label>
                                  <label>{row.updateDate}</label>
                                </div>
                              </div>
                            }
                            placement="bottom"
                            trigger="click"
                          >
                            <img src={InfoIcon} alt="InfoIcon" />
                          </Popover>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Status:</label>
                        <label className="expandemailtext">
                          {row.isActive ? "Active" : "Inactive"}
                        </label>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }}
            expandIcon={ ({ expanded, onExpand, record }) =>
            expanded ? (
              <div className="expandown1">
                <img src={Down} onClick={e => onExpand(record, e)} />
              </div>
            ) : (
              <div className="expandown">
                <img src={Down} onClick={e => onExpand(record, e)} />
              </div>
            )}
            expandIconColumnIndex={this.state.mobileView ? 8 : -1}
            expandIconAsCell={false}
            dataSource={this.state.rolesPermissionsData}
            pagination={{position:["bottomCenter"]}}
          />
        </div>
        {/* Add Role */}
        <Modal
          open={this.state.addRole}
          onClose={this.handleaddRoleClose.bind(this)}
          modalId="addroleModal"
          overlayId="overlay"
        >
          <div className="backtext">
            <h3 className="eduser">Add Roles & Permissions</h3>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              className="closeicon"
              onClick={this.handleaddRoleClose.bind(this)}
            />
          </div>
          <div className="edituser">
            <div className="row">
              <div className="col-12 col-md-6">
                <label>Role</label>
                <input
                  type="text"
                  placeholder="Enter Role"
                  name="name"
                  value={this.state.roleInfo.name}
                  onChange={this.handleInputOnchange}
                />
                {this.state.roleInfo.name.length === 0 &&
                  this.state.touched && (
                    <span className="Error">Required.</span>
                  )}
              </div>
              <div className="col-12 col-md-6">
                <label>Mapped Modules</label>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Modules"
                  // defaultValue={["a10", "c12"]}
                  //value={this.state.roleInfo.module}
                  onChange={this.handleAddModules}
                  onBlur={this.handleGetMappedSubModulesList.bind(this)}
                >
                  {this.state.mappedModules &&
                    this.state.mappedModules.map((module) => (
                      <Option key={module.moduleId} value={module.moduleId}>
                        {module.moduleName}
                      </Option>
                    ))}
                </Select>
                {this.state.roleInfo.modules.length === 0 &&
                  this.state.touched && (
                    <span className="Error">Required.</span>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label>Mapped Sub Modules</label>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Sub Modules"
                  //value={this.state.roleInfo.subModule}
                  // defaultValue={["a10", "c12"]}
                  onChange={this.handleAddSubModules}
                >
                  {this.state.mappedSubModules &&
                    this.state.mappedSubModules.map((subModule) => (
                      <Option
                        key={subModule.subModuleMasterId}
                        value={subModule.subModuleMasterId}
                      >
                        {subModule.subModuleName}
                      </Option>
                    ))}
                </Select>
                {this.state.roleInfo.subModules.length === 0 &&
                  this.state.touched && (
                    <span className="Error">Required.</span>
                  )}
              </div>
              <div className="col-12 col-md-6">
                <label>Status</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="status"
                    value={this.state.roleInfo.status}
                    onChange={this.handleInputOnchange}
                    checked={this.state.roleInfo.status}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="Editbtn">
              <button className="btn" onClick={this.handleAddRole.bind(this)}>
                Add
              </button>
            </div>
          </div>
        </Modal>
        {/* Edit Role */}
        <Modal
          open={this.state.editRole}
          onClose={this.handdleeditRoleClose.bind(this)}
          modalId="addroleModal"
          overlayId="overlay"
        >
          <div className="backtext">
            <h3 className="eduser">Edit Roles & Permissions</h3>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              className="closeicon"
              onClick={this.handdleeditRoleClose.bind(this)}
            />
          </div>
          <div className="edituser">
            <div className="row">
              <div className="col-12 col-md-6">
                <label>Role</label>
                <input
                  type="text"
                  placeholder="Enter Role"
                  name="name"
                  value={this.state.roleInfo.name}
                  onChange={this.handleInputOnchange}
                />
                {this.state.roleInfo.name.length === 0 &&
                  this.state.touched && (
                    <span className="Error">Required.</span>
                  )}
              </div>
              <div className="col-12 col-md-6">
                <label>Mapped Modules</label>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Modules"
                  // defaultValue={["a10", "c12"]}
                  defaultValue={this.state.roleInfo.modules}
                  onChange={this.handleAddModules}
                  onBlur={this.handleGetMappedSubModulesList.bind(this)}
                >
                  {this.state.mappedModules &&
                    this.state.mappedModules.map((module) => (
                      <Option key={module.moduleId}>{module.moduleName}</Option>
                    ))}
                </Select>
                {this.state.roleInfo.modules.length === 0 &&
                  this.state.touched && (
                    <span className="Error">Required.</span>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <label>Mapped Sub Modules</label>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Sub Modules"
                  // defaultValue={["a10", "c12"]}
                  defaultValue={this.state.roleInfo.subModules}
                  onChange={this.handleAddSubModules}
                >
                  {this.state.mappedSubModules &&
                    this.state.mappedSubModules.map((subModule) => (
                      <Option key={subModule.subModuleMasterId}>
                        {subModule.subModuleName}
                      </Option>
                    ))}
                </Select>
                {this.state.roleInfo.subModules.length === 0 &&
                  this.state.touched && (
                    <span className="Error">Required.</span>
                  )}
              </div>
              <div className="col-12 col-md-6">
                <label>Status</label>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="status"
                    value={this.state.roleInfo.status}
                    onChange={this.handleInputOnchange}
                    checked={this.state.roleInfo.status}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="Editbtn">
              <button className="btn" onClick={this.handleEditRole.bind(this)}>
                Edit
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default rolesPermission;
