import React, { Component } from 'react'
import { Table, Popover } from "antd";
import RedDelete from "./../../assets/Images/delete.png";
import BlueEdit from "./../../assets/Images/editt.png";
import Modal from "react-responsive-modal";
import CloseIcon from "./../../assets/Images/CloseWhBold.png";
import Down from "./../../assets/Images/download.png";

class paymentDetail extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            addCard: false,
            editCard: false,
            mobileView: false,
        }
    }
    componentDidMount() {
        if (window.screen.width > 768) {
          this.setState({
            mobileView: false,
          });
        } else {
          this.setState({
            mobileView: true,
          });
        }
      }
    handleaddCardOpen() {
        this.setState({ addCard: true });
    }
    handleaddCardClose() {
        this.setState({ addCard: false });
    }
    handleeditCardOpen() {
        this.setState({ editCard: true });
    }
    handleeditCardClose() {
        this.setState({ editCard: false });
    }
    render() {
        const columns = [
            {
              title: "No.",
              dataIndex: "no",
              key: "no",
            },
            {
              title: "Card Info.",
              dataIndex: "cardin",
              key: "cardin",
            },
            {
              title: "Card Holder Name",
              dataIndex: "cardname",
              key: "cardname",
              className: "mob-none",
            },
            {
              title: "Card Expiry",
              dataIndex: "cardexpiry",
              key: "cardexpiry",
              className: "mob-none",
            },
            {
              title: "Primary Card",
              dataIndex: "primarycard",
              key: "primarycard",
              className: "mob-none",
            },
            {
                title: "Actions",
                dataIndex: "action",
                key: "Action",
                render: (row, item) => {
                  return (
                    <div className="action">
                      <div className="editdele">
                        <img src={BlueEdit} alt="Edit" onClick={this.handleeditCardOpen.bind(this)} />
                        <Popover
                          content={
                            <div className="deletepopover text-center">
                              <h3>Are you sure to delete ?</h3>
                              <button className="delete">Cancel</button>
                              <button className="delete"> Delete</button>
                            </div>
                          }
                          placement="bottomRight"
                          trigger="click"
                        >
                          <img src={RedDelete} alt="Delete" />
                        </Popover>
                      </div>
                    </div>
                  );
                },
              },
          ];
      
          const data = [
            {
              key: "1",
              no: "1",
              cardin: <label>Credit Card ending with 7896</label>,
              cardname: <label>Lorem Ipsum</label>,
              cardexpiry: <label>10-2023</label>,
              primarycard: 
                <div className="radio-item">
                        <input type="radio" id="card1" name="card" value="ca1" />
                        <label htmlFor="card1"></label>
                </div>
            },
            {
              key: "2",
              no: "2",
              cardin: <label>Credit Card ending with 5647</label>,
              cardname: <label>Lorem Ipsum</label>,
              cardexpiry: <label>01-2025</label>,
              primarycard: 
              <div className="radio-item">
                      <input type="radio" id="card2" name="card" value="ca2" />
                      <label htmlFor="card2"></label>
              </div>
            },
            {
              key: "3",
              no: "3",
              cardin: <label>Credit Card ending with 4509</label>,
              cardname: <label>Lorem Ipsum</label>,
              cardexpiry: <label>04-2021</label>,
              primarycard: 
                <div className="radio-item">
                        <input type="radio" id="card3" name="card" value="ca3" />
                        <label htmlFor="card3"></label>
                </div>
            },
          ];
        return (
            <div>
                <div className="userpayment">
                    <h3 className="Usermana">Add Card Details</h3>
                    <label className="add" onClick={this.handleaddCardOpen.bind(this)}>Add New</label>
                    <div className="userpaymenttable">
                        <Table
                            columns={columns}
                            expandedRowRender={(row) => {
                                return (
                                  <React.Fragment>
                                    <div className="row">
                                      <div className="col-12 col-sm-6 mb-3">
                                        <div className="mobilevi">
                                          <label className="expandemail">Card Info:</label>
                                          <label className="expandemailtext">{row.cardin}</label>
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-6 mb-3">
                                        <div className="mobilevi">
                                          <label className="expandemail">Card Holder Name:</label>
                                          <label className="expandemailtext">
                                            {row.cardname}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 col-sm-6 mb-3">
                                        <div className="mobilevi">
                                          <label className="expandemail">Card Expiry:</label>
                                          <label className="expandemailtext">
                                            {row.cardexpiry}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 col-sm-6 mb-3">
                                        <div className="mobilevi">
                                          <label className="expandemail">Status:</label>
                                          <label className="expandemailtext">
                                            {row.isActive ? "Active" : "Inactive"}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              }}
                              expandIcon={ ({ expanded, onExpand, record }) =>
                              expanded ? (
                                <div className="expandown1">
                                  <img src={Down} onClick={e => onExpand(record, e)} />
                                </div>
                              ) : (
                                <div className="expandown">
                                  <img src={Down} onClick={e => onExpand(record, e)} />
                                </div>
                              )}
                              expandIconColumnIndex={this.state.mobileView ? 6 : -1}
                              expandIconAsCell={false}
                            dataSource={data}
                            pagination={{position:["bottomCenter"]}}
                        />             
                    </div>
                    {/* Add Role */}
                    <Modal
                    open={this.state.addCard}
                    onClose={this.handleaddCardClose.bind(this)}
                    modalId="addcardModal"
                    overlayId="overlay"
                    >
                    <div className="backtext">
                        <h3 className="eduser">Add Card Details</h3>
                        <img src={CloseIcon} alt="CloseIcon" className="closeicon"
                           onClick={this.handleaddCardClose.bind(this)} />
                    </div>
                    <div className="edituser">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="marginbot">
                                    <label>Card Holder Name</label>
                                    <input type="text" placeholder="Enter card holder name" />
                                    <span class="Error">Required Field</span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="marginbot">
                                    <label>Card Number</label>
                                    <input type="text" placeholder="Enter card number" />
                                    <span class="Error">Required Field</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="marginbot">
                                <label>Expiry Date</label>
                                <div className="d-flex">
                                    <select className="mr-1">
                                        <option>Month</option>
                                        <option>Month 1</option>
                                        <option>Month 2</option>
                                    </select>
                                    <select>
                                        <option>Year</option>
                                        <option>Year 1</option>
                                        <option>Year 2</option>
                                    </select>
                                </div>
                                <span class="Error">Required Field</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                           <div className="marginbot">
                                <label>Zip/Postal Code</label>
                                <input type="text" placeholder="Enter Zip/Postal Code" />
                                <span class="Error">Required Field</span>
                           </div>
                        </div>
                        </div>
                        <div className="Editbtn">
                        <button className="btn">Add</button>
                        </div>
                    </div>
                    </Modal>
                    {/* Edit Role */}
                    <Modal
                    open={this.state.editCard}
                    onClose={this.handleeditCardClose.bind(this)}
                    modalId="addcardModal"
                    overlayId="overlay"
                    >
                    <div className="backtext">
                        <h3 className="eduser">Edit Card Details</h3>
                        <img src={CloseIcon} alt="CloseIcon" className="closeicon"
                           onClick={this.handleeditCardClose.bind(this)} />
                    </div>
                    <div className="edituser">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="marginbot">
                                    <label>Card Holder Name</label>
                                    <input type="text" placeholder="Enter card holder name" />
                                    <span class="Error">Required Field</span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="marginbot">
                                    <label>Card Number</label>
                                    <input type="text" placeholder="Enter card number" />
                                    <span class="Error">Required Field</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="marginbot">
                                <label>Expiry Date</label>
                                <div className="d-flex">
                                    <select className="mr-1">
                                        <option>Month</option>
                                        <option>Month 1</option>
                                        <option>Month 2</option>
                                    </select>
                                    <select>
                                        <option>Year</option>
                                        <option>Year 1</option>
                                        <option>Year 2</option>
                                    </select>
                                </div>
                                <span class="Error">Required Field</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                           <div className="marginbot">
                                <label>Zip/Postal Code</label>
                                <input type="text" placeholder="Enter Zip/Postal Code" />
                                <span class="Error">Required Field</span>
                           </div>
                        </div>
                        </div>
                        <div className="Editbtn">
                        <button className="btn">Save</button>
                        </div>
                    </div>
                    </Modal>
                    <div className="pagination">
                        <ul>
                            <li><a hrf="">&lt;</a></li>
                            <li className="active"><a hrf="">1</a></li>
                            <li><a hrf="">2</a></li>
                            <li><a hrf="">3</a></li>
                            <li><a hrf="">4</a></li>
                            <li><a hrf="">5</a></li>
                            <li><a hrf="">&gt;</a></li>
                        </ul>
                        <div className="selectopt">
                            <select>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            </select>
                        </div>
                    </div>  
                </div>
            </div>
        )
    }
}

export default paymentDetail
