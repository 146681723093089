import React, { Component } from "react";
import axios from "axios";
import config from "./../../helpers/config";
import { authHeader } from "./../../helpers/authHeader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

class changePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      loading: false,
      cameFrom: "",
    };
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  componentDidMount() {
    debugger;
    const cameFrom = this.props.location.state.cameFrom;
    this.setState({
      cameFrom,
    });
  }

  handleChangePassword = (e) => {
    e.preventDefault();
    debugger;
    if (
      this.state.oldPassword &&
      this.state.newPassword &&
      this.state.confirmPassword
    ) {
      if (this.state.newPassword.length < 8) {
        return NotificationManager.error(
          "Password length must be atleast 8 characters.",
          "",
          1500
        );
      }
      var reg = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
      if (reg.test(this.state.newPassword) === false) {
        return NotificationManager.error(
          "Password must have atleast one alphabet, one numeric and one special character.",
          "",
          1500
        );
      }

      if (this.state.newPassword !== this.state.confirmPassword)
        return NotificationManager.error(
          "New Password and Confirm Password don't match",
          "",
          1500
        );
      var self = this;
      self.setState({
        loading: true,
      });
      var json = {
        Oldpassword: this.state.oldPassword,
        NewPassword: this.state.newPassword,
        ConfirmPassword: this.state.confirmPassword,
      };
      axios({
        method: "post",
        url: config.apiUrl + "AccountAdmin/ChangePassword",
        headers: authHeader(),
        data: json,
      })
        .then(function (res) {
          debugger;
          let msg = res.data.message;
          self.setState({
            loading: false,
          });
          if (msg === "Success") {
            NotificationManager.success(
              "Password changed successfully.",
              "",
              1500
            );
            if (self.state.cameFrom === "Admin") {
              setTimeout(() => {
                self.props.history.push("/admin/adminProfile");
              }, 1600);
            } else if (self.state.cameFrom === "Merchant") {
              setTimeout(() => {
                self.props.history.push("/merchant/merchantProfile");
              }, 1600);
            } else if (self.state.cameFrom === "User") {
              setTimeout(() => {
                self.props.history.push("/user/userProfile");
              }, 1600);
            }
          } else if (msg === "Invalid Current Password") {
            NotificationManager.error(msg, "", 1500);
          }
        })
        .catch((data) => {
          console.log(data);
          self.setState({
            loading: false,
          });
        });
    } else {
      NotificationManager.error("The credentials cannot be empty.", "", 1500);
    }
  };

  /// handle input onchange
  handleInputOnchange = (e) => {
    debugger;
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <div className="outer-sign-in">
        <NotificationContainer />
        <div className="text-center w-100">
          <h1 className="logo-text">INSTANT PAY</h1>
          <div className="sign-in-card">
            <label className="sign-in">Change Password</label>
            <form
              name="reset-password-form"
              onSubmit={this.handleChangePassword}
            >
              <div className="input-cntr">
                <input
                  type="password"
                  placeholder="Old Password"
                  name="oldPassword"
                  value={this.state.oldPassword}
                  onChange={this.handleInputOnchange}
                />
              </div>
              <div className="input-cntr">
                <input
                  type="password"
                  placeholder="New Password"
                  name="newPassword"
                  value={this.state.newPassword}
                  onChange={this.handleInputOnchange}
                />
              </div>
              <div className="input-cntr">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={this.state.confirmPassword}
                  onChange={this.handleInputOnchange}
                />
              </div>
              <button
                type="submit"
                className="butn mx-auto"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={faCircleNotch}
                    size="sm"
                    spin
                  />
                )}
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default changePassword;
