import React, { Component } from "react";
import Filter from "./../../assets/Images/filter.png";
import CSV from "./../../assets/Images/csv.png";
import WhiteDropdown from "./../../assets/Images/WhiteDropdown.png";
import InfoIcon from "./../../assets/Images/Infoblue.png";
import { Table, Popover, DatePicker } from "antd";
import config from "./../../helpers/config";
import axios from "axios";
import { authHeader } from "./../../helpers/authHeader";
import Item from "antd/lib/list/Item";
import { CSVLink } from "react-csv";
import { Drawer } from "antd";
import Down from "./../../assets/Images/download.png";

const { RangePicker } = DatePicker;

const dateFormat = "DD-MM-YYYY";

class transactionHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilter: false,
      transactionHistoryData: [],
      orderId: "",
      emailId: "",
      contactNo: "",
      startDate: null,
      endDate: null,
      transactionAmountFrom: "",
      transactionAmountTo: "",
      visibleFilter: false,
      placement: "bottom",
      mobileView: false,
    };
  }
  showDrawerFilter = () => {
    this.setState({ visibleFilter: true });
  };
  onCloseFilter = () => {
    this.setState({ visibleFilter: false });
  };
  onChange = (e) => {
    this.setState({ placement: e.target.value });
  };
  handleFilterbuttonClick = () => {
    this.setState({ isFilter: !this.state.isFilter });
  };

  componentDidMount() {
    this.handleGetTransactionHistoryList();
    if (window.screen.width > 768) {
      this.setState({
        mobileView: false,
      });
    } else {
      this.setState({
        mobileView: true,
      });
    }
  }

  handleValidation() {
    let errors = {};
    let formIsValid = true;
    if (
      this.state.transactionAmountFrom !== "" &&
      this.state.transactionAmountTo !== ""
    ) {
      if (
        parseInt(this.state.transactionAmountFrom) >
        parseInt(this.state.transactionAmountTo)
      ) {
        formIsValid = false;
        errors["transactionAmountFrom"] =
          "Transaction from should be less than transaction to.";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleGetTransactionHistoryList() {
    let self = this;

    if (this.handleValidation()) {
      axios({
        method: "post",
        url: config.apiUrl + "MerchantTransaction/TransactionHistory",
        headers: authHeader(),
        data: {
          OrderId: this.state.orderId,
          StartDate: this.state.startDate == "" ? null : this.state.startDate,
          EndDate: this.state.endDate == "" ? null : this.state.endDate,
          TransactionAmountFrom:
            this.state.transactionAmountFrom == ""
              ? 0
              : parseInt(this.state.transactionAmountFrom),
          TransactionAmountTo:
            this.state.transactionAmountTo == ""
              ? 0
              : parseInt(this.state.transactionAmountTo),
          EmailId: this.state.emailId,
          ContactNumber: this.state.contactNo,
        },
      })
        .then(function (res) {
          debugger;
          let status = res.data.message;
          let data = res.data.responseData;
          if (status === "Success") {
            self.setState({
              transactionHistoryData: data,
            });
          } else {
            self.setState({
              transactionHistoryData: [],
            });
          }
        })
        .catch((data) => {
          console.log(data);
        });
    }
  }

  handleRowClickPage(transactionID, orderID, userID) {
    debugger;
    let self = this;

    setTimeout(function () {
      self.props.history.push({
        pathname: "transaction-history-details",
        transactionId: transactionID,
        orderId: orderID,
        userId: userID,
      });
    }, 1000);
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleDateOnChange = (dates, dateStrings) => {
    this.setState({ startDate: dateStrings[0], endDate: dateStrings[1] });
  };

  render() {
    function disabledDate(current) {
      // Can not select days before today and today
      return current > new Date();
    }
    const { placement, visibleFilter } = this.state;
    const merchantinfo = (
      <div className="merrefunfpopover">
        <div className="subsc">
          <label>Refund</label>
          <label>xyz Refund</label>
        </div>
        <div className="subsc">
          <label>Refund</label>
          <label>xyz Refund</label>
        </div>
        <div className="subsc">
          <label>Refund</label>
          <label>xyz Refund</label>
        </div>
      </div>
    );
    const DeleteUser = (
      <div className="deletepopover text-center">
        <h3>Are you sure to refund ?</h3>
        <button className="delete">Cancel</button>
        <button className="refundbt">Refund</button>
      </div>
    );

    const headers = [
      { label: "Transaction Date", key: "transactionDate" },
      { label: "Order Id", key: "orderId" },
      { label: "Transaction Id", key: "transactionID" },
      { label: "Transaction Value (AU$)", key: "amount" },
      { label: "Email", key: "emailId" },
      { label: "Contact Number", key: "contactNumber" },
      { label: "Payment Details", key: "customerCard" },
      { label: "Status", key: "paymentStatus" },
    ];

    const columns = [
      {
        title: "Date",
        dataIndex: "transactionDate",
        key: "transactionDate",
        
      },
      {
        title: "Order Id",
        dataIndex: "orderId",
        key: "orderId",
        
      },
      {
        title: "Transaction Id",
        dataIndex: "transactionID",
        key: "transactionID",
        className: "mob-none",
      },
      {
        title: "Transaction Value (AU$)",
        dataIndex: "amount",
        key: "amount",
        className: "mob-none",
      },
      {
        title: "Email",
        dataIndex: "emailId",
        key: "emailId",
        className: "mob-none",
      },
      {
        title: "Contact Number",
        dataIndex: "contactNumber",
        key: "contactNumber",
        className: "mob-none",
      },
      {
        title: "Payment Details",
        dataIndex: "customerCard",
        key: "customerCard",
        className: "mob-none",
      },
      {
        title: "Status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        render: (row, item) => {
          return (() => {
            if (item.paymentStatus.toLowerCase().trim() === "refunded") {
              return (
                <div className="amazontext">
                  <label className="refundtext">{item.paymentStatus}</label>
                  <Popover
                    content={
                      <div className="merrefunfpopover">
                        <div className="subsc">
                          <label>Refunded Date:</label>
                          <label>{item.refundDate}</label>
                        </div>
                      </div>
                    }
                    placement="bottom"
                    trigger="click"
                  >
                    <img src={InfoIcon} alt="InfoIcon" />
                  </Popover>
                </div>
              );
            } else if (item.paymentStatus.toLowerCase().trim() === "success") {
              return (
                <div className="amazontext">
                  <label className="success">{item.paymentStatus}</label>
                </div>
              );
            } else if (item.paymentStatus.toLowerCase().trim() === "failed") {
              return (
                <div className="amazontext">
                  <label className="failed">{item.paymentStatus}</label>
                </div>
              );
            } else if (item.paymentStatus.toLowerCase().trim() === "progress") {
              return (
                <div className="amazontext">
                  <label className="custom-link">{item.paymentStatus}</label>
                </div>
              );
            } else if (
              item.paymentStatus.toLowerCase().trim() === "refund init"
            ) {
              return (
                <div className="amazontext">
                  <label className="refundtext">{item.paymentStatus}</label>
                </div>
              );
            }
          })();
        },
      },
    ];

    return (
      <div className="MerTraHistory">
        <h3 className="Usermana">Transaction History</h3>
        <div className="exfilter">
          <CSVLink
            data={this.state.transactionHistoryData}
            headers={headers}
            filename={"Transaction History.csv"}
            className="csv"
          >
            <img src={CSV} alt="Export" />
            Export to CSV
          </CSVLink>
          <label
            className="filte"
            onClick={this.handleFilterbuttonClick.bind(this)}
          >
            <img src={Filter} alt="Export" />
            Filter
            <img src={WhiteDropdown} alt="Dropdown" className="WhDrop" />
          </label>
        </div>
        <label className="filt" onClick={this.showDrawerFilter.bind(this)}>
          <img src={Filter} alt="Export" />
        </label>
        {this.state.isFilter ? (
          <div className="row m-0 w-100 back">
            <div className="col-12 col-md-3">
              <input
                type="text"
                placeholder="Enter Order Id"
                name="orderId"
                value={this.state.orderId}
                onChange={this.handleOnChange.bind(this)}
              />
            </div>
            <div className="col-12 col-md-3">
              <input
                type="text"
                placeholder="Enter Email"
                name="emailId"
                value={this.state.emailId}
                onChange={this.handleOnChange.bind(this)}
              />
            </div>
            <div className="col-12 col-md-3">
              <input
                type="text"
                placeholder="Enter Contact No."
                name="contactNo"
                value={this.state.contactNo}
                onChange={this.handleOnChange.bind(this)}
              />
            </div>
            <div className="col-12 col-md-3">
              {/* <input type="text" className="calendar" placeholder="Start Date - End Date" /> */}
              <RangePicker
                className="calendar"
                format={dateFormat}
                onChange={this.handleDateOnChange}
                disabledDate={disabledDate}
              ></RangePicker>
            </div>
            <div className="col-12 col-md-3">
              <input
                type="text"
                placeholder="Enter Total Amount Txn From"
                name="transactionAmountFrom"
                value={this.state.transactionAmountFrom}
                onChange={this.handleOnChange.bind(this)}
              />
              <span className="Error">
                {this.state.errors["transactionAmountFrom"]}
              </span>
            </div>
            <div className="col-12 col-md-3">
              <input
                type="text"
                placeholder="Enter Total Amount Txn To"
                name="transactionAmountTo"
                value={this.state.transactionAmountTo}
                onChange={this.handleOnChange.bind(this)}
              />
            </div>
            <div className="col-12 col-md-12">
              <div className="search">
                <button
                  onClick={this.handleGetTransactionHistoryList.bind(this)}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        ) : null}
        <div className="MerchantrHistorytable">
          <Table
            columns={columns}
            expandedRowRender={(row) => {
              return (
                <React.Fragment>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Date:</label>
                        <label className="expandemailtext">{row.transactionDate}</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Order Id:</label>
                        <label className="expandemailtext">{row.orderId}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Transaction Id:</label>
                        <label className="expandemailtext">{row.transactionID}</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Transaction Value (AU$):</label>
                        <label className="expandemailtext">{row.amount}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Email:</label>
                        <label className="expandemailtext">{row.emailId}</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Contact Number:</label>
                        <label className="expandemailtext">{row.contactNumber}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Payment Details:</label>
                        <label className="expandemailtext">{row.customerCard}</label>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Created By:</label>
                        <div className="amazontext">
                          <label className="expandemailtext">
                            {row.createBy}
                          </label>
                          <Popover
                            content={
                              <div className="userpopover">
                                <div className="subsc">
                                  <label>Created By</label>
                                  <label>{row.createBy}</label>
                                </div>
                                <div className="subsc">
                                  <label>Created On</label>
                                  <label>{row.createDate}</label>
                                </div>
                                <div className="subsc">
                                  <label>Modified By</label>
                                  <label>{row.updateBy}</label>
                                </div>
                                <div className="subsc">
                                  <label>Modified On</label>
                                  <label>{row.updateDate}</label>
                                </div>
                              </div>
                            }
                            placement="bottom"
                            trigger="click"
                          >
                            <img src={InfoIcon} alt="InfoIcon" />
                          </Popover>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                      <div className="mobilevi">
                        <label className="expandemail">Status:</label>
                        <label className="expandemailtext">
                          {row.isActive ? "Active" : "Inactive"}
                        </label>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            }}
            expandIcon={ ({ expanded, onExpand, record }) =>
            expanded ? (
              <div className="expandown1">
                <img src={Down} onClick={e => onExpand(record, e)} />
              </div>
            ) : (
              <div className="expandown">
                <img src={Down} onClick={e => onExpand(record, e)} />
              </div>
            )}
            expandIconColumnIndex={this.state.mobileView ? 8 : -1}
            expandIconAsCell={false}
            dataSource={this.state.transactionHistoryData}
            pagination={{position:["bottomCenter"]}}
            onRow={(row, item) => ({
              onClick: () =>
                this.handleRowClickPage(
                  row.transactionID,
                  row.orderId,
                  row.userID
                ),
            })}
          />
        </div>
        <div className="fl">
          <Drawer
            placement={placement}
            closable={false}
            onClose={this.onCloseFilter}
            visible={visibleFilter}
            key={placement}
            className="f2"
          >
            <div className="drarfilter">
              <div className="row m-0 w-100 back">
                <div className="col-12 col-md-3">
                  <input
                    type="text"
                    placeholder="Enter Order Id"
                    name="orderId"
                    value={this.state.orderId}
                    onChange={this.handleOnChange.bind(this)}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <input
                    type="text"
                    placeholder="Enter Email"
                    name="emailId"
                    value={this.state.emailId}
                    onChange={this.handleOnChange.bind(this)}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <input
                    type="text"
                    placeholder="Enter Contact No."
                    name="contactNo"
                    value={this.state.contactNo}
                    onChange={this.handleOnChange.bind(this)}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <RangePicker
                    className="calendar"
                    format={dateFormat}
                    onChange={this.handleDateOnChange}
                    disabledDate={disabledDate}
                  ></RangePicker>
                </div>
                <div className="col-12 col-md-3">
                  <input
                    type="text"
                    placeholder="Total Amount Transacted From"
                    name="transactionAmountFrom"
                    value={this.state.transactionAmountFrom}
                    onChange={this.handleOnChange.bind(this)}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <input
                    type="text"
                    placeholder="Total Amount Transacted To"
                    name="transactionAmountTo"
                    value={this.state.transactionAmountTo}
                    onChange={this.handleOnChange.bind(this)}
                  />
                </div>
                <div className="col-12 col-md-12">
                  <div className="search">
                    <button
                      onClick={this.onCloseFilter.bind(this)}
                      className="mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={this.handleGetTransactionHistoryList.bind(this)}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    );
  }
}

export default transactionHistory;
