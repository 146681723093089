import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import dashboard from "./../../assets/Images/dashboard.png";
import merchant from "./../../assets/Images/merchant.png";
import user from "./../../assets/Images/user.png";
import setting from "./../../assets/Images/setting.png";
import notification from "./../../assets/Images/notification.png";
import profile from "./../../assets/Images/profile.jpg";
import Edit from "./../../assets/Images/edit.png";
import Modal from "react-responsive-modal";
import Bluser from "./../../assets/Images/BlUser.png";
import axios from "axios";
import config from "./../../helpers/config";
import { authHeader } from "./../../helpers/authHeader";
import Cookies from "universal-cookie";
import { NotificationManager } from "react-notifications";
import { Drawer, Button, Space } from "antd";
import menu from "./../../assets/Images/menu.png";
import logoutwhite from "./../../assets/Images/logoutwhite.png";

const cookies = new Cookies();

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: false,
      notificationCount: 0,
      visible: false,
      placement: "left",
      userName: "",
      emailId: ""
    };
  }

  componentDidMount() {
    var userName = window.localStorage.getItem("username");
    var emailId = window.localStorage.getItem("emailid");
    this.setState({
      userName,
      emailId
    })
    this.handleGetNotificationsCount();
    setInterval(() => {
      this.handleGetNotificationsCount();
    }, 5000);
  }

  handleGetNotificationsCount() {
    let self = this;

    axios({
      method: "get",
      url: config.apiUrl + "MerchantDashboard/NotificationCount",
      headers: authHeader(),
    })
      .then(function (res) {
        let status = res.data.message;
        let notificationCount = res.data.responseData;
        if (status === "Success") {
          self.setState({
            notificationCount,
          });
        } else {
          self.setState({
            notificationCount: 0,
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  showDrawer = () => {
    this.setState({ visible: true });
  };
  onClose = () => {
    this.setState({ visible: false });
  };
  onChange = (e) => {
    this.setState({ placement: e.target.value });
  };
  handleUserOpen() {
    this.setState({ user: true });
  }
  handleUserClose() {
    this.setState({ user: false });
  }
  handleLogout() {
    axios({
      method: "get",
      url: config.apiUrl + "AccountAdmin/Logout",
      headers: authHeader(),
    })
      .then(function (res) {
        debugger;
        if (res.status) {
          window.location.href = "/";
          cookies.remove("token");
        } else {
          NotificationManager.error("Not able to logout.");
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  render() {
    const { placement, visible } = this.state;
    return (
      <div className="header">
        <h1 className="header-logo">INSTANT PAY</h1>
        <div className="header-nav">
          <ul className="header-left">
            <li>
              <Link to="/merchant/dashboard">
                <div className="header-icons">
                  <img src={dashboard} alt="icon missing" />
                </div>
                <span className="ml-2">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/merchant/transaction-history">
                <div className="header-icons">
                  <img src={user} alt="icon missing" />
                </div>
                <span className="ml-2">Transaction History</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/merchant/salesReport">
                <div className="header-icons">
                  <img src={merchant} alt="icon missing" />
                </div>
                <span className="ml-2">Sales Report</span>
              </Link>
            </li> */}
            <li>
              <Link to="/merchant/merchantSubscription">
                <div className="header-icons">
                  <img src={merchant} alt="icon missing" />
                </div>
                <span className="ml-2">Subscription</span>
              </Link>
            </li>
          </ul>
          <div className="header-right">
            <ul className="d-flex">
              <li className="header-icons">
                <Link
                  to="/merchant/notification"
                  className="position-relative"
                  onClick={this.handleGetNotificationsCount}
                >
                  <img src={notification} alt="icon missing" />
                  {this.state.notificationCount !== 0 && (
                    <span className="notification-count">
                      {this.state.notificationCount}
                    </span>
                  )}
                </Link>
              </li>
              <li className="header-icons">
                <Link to="/merchant/merchantSetting">
                  <img src={setting} alt="icon missing" />
                </Link>
              </li>
            </ul>
            <div className="user-profile">
              <div
                className="profile-img"
                onClick={this.handleUserOpen.bind(this)}
              >
                <img src={profile} alt="img missing" />
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View Header */}
        <div className="sidebarresp">
          <img
            className="menubtn"
            src={menu}
            alt="menu"
            onClick={this.showDrawer.bind(this)}
          />
          <ul className="d-flex">
            <li className="header-icons">
              <Link to="/merchant/notification" className="position-relative">
                <img src={notification} alt="icon missing" />
                <span className="notification-count">18</span>
              </Link>
            </li>
            <li className="header-icons">
              <Link to="/merchant/merchantSetting">
                <img src={setting} alt="icon missing" />
              </Link>
            </li>
            <li className="header-icons">
              <Link to="/admin/adminProfile">
                <img className="profilerep" src={profile} alt="profile" />
              </Link>
            </li>
          </ul>
          <Drawer
            title="INSTANT PAY"
            placement={placement}
            closable={false}
            onClose={this.onClose}
            visible={visible}
            key={placement}
          >
            <ul className="respnavb">
              <li className="respbarli" onClick={this.onClose.bind(this)}>
                <NavLink to="/merchant/dashboard">
                  <img src={dashboard} alt="icon missing" />
                  <span className="ml-2 sidbarspan"> Dashboard</span>
                </NavLink>
              </li>
              <li className="respbarli" onClick={this.onClose.bind(this)}>
                <NavLink to="/merchant/transaction-history">
                  <img src={user} alt="icon missing" />
                  <span className="ml-2 sidbarspan">Transaction History</span>
                </NavLink>
              </li>
              <li className="respbarli" onClick={this.onClose.bind(this)}>
                <NavLink to="/merchant/merchantSubscription">
                  <img src={merchant} alt="icon missing" />
                  <span className="ml-2 sidbarspan">Subscription</span>
                </NavLink>
              </li>
            </ul>
            <div
              className="respbarli logoutbg"
              onClick={this.handleLogout.bind(this)}
            >
              <Link to="">
                <img
                  className="logouticon"
                  src={logoutwhite}
                  alt="logoutwhite"
                />
                <span className="ml-2 sidbarspan">Logout</span>
              </Link>
            </div>
          </Drawer>
        </div>
        {/* Modal User */}
        <Modal
          open={this.state.user}
          onClose={this.handleUserClose.bind(this)}
          modalId="HeaderUserModal"
          overlayId="overlay"
        >
          <div className="mainuser">
            <div className="usericon">
              <img src={Bluser} alt="UserIcon" />
            </div>
            <div className="usertext">
              <label>
                {this.state.userName}
                <Link to="/merchant/merchantProfile">
                  <img src={Edit} alt="Edit" />
                </Link>
              </label>
              <label>{this.state.emailId}</label>
            </div>
            <div className="Logout">
              <button className="btn" onClick={this.handleLogout.bind(this)}>
                Logout
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Header;
