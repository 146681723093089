import React, { Component } from 'react';
import { Link } from "react-router-dom";
import UserIc from "../../assets/Images/avatar.png";
import UserIc1 from "../../assets/Images/Payment-Management.png";
import Back from "../../assets/Images/back.png";


class signUpHome extends Component {
    render() {
        return (
            <div className="marketingSignHome">
                <div className="merchantMar">
                    <header>
                        <div className="inpay">
                            <Link to="/home">
                                <h4>Instant Pay</h4>
                            </Link>
                        </div>
                        <div className="foryou">
                            <ul>
                                <li>
                                    <Link to="/userSignUp">
                                        <a>For You</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/signUp">
                                        <a>For Your Business</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/setUp">
                                        <a>Set - Up</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/help">
                                        <a>Help</a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="signbtn">
                            <Link to="/">
                                <button type="button" className="btn sigin">Sign In</button>
                            </Link>
                            <Link to="/signUpHome">
                                <button type="button" className="btn sigup">Sign Up</button>
                            </Link>
                        </div>
                    </header>
                </div>
                <div className="container topbottom">
                    <div className="mainCard">
                        <div className="card">
                            <div className="Circleimg">
                                <img src={UserIc} alt="Circle" />
                            </div>
                            <h4>For You</h4>
                            <p>Lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <div className="signup">
                                <Link to="/userSignUp">
                                    <button type="button">Sign Up Now</button>
                                </Link>
                            </div>

                            <div className="Video">
                                <a href="https://youtu.be/U6fC4Ij608A" target="_blank">
                                    <img src={Back} alt="Video" />
                                </a>
                            </div>

                        </div>
                        <div className="card">
                            <div className="Circleimg">
                                <img src={UserIc1} alt="Circle" />
                            </div>
                            <h4>For Your Business</h4>
                            <p>Lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <div className="signup1">
                                <Link to="/signUp">
                                    <button type="button">Sign Up Now</button>
                                </Link>
                            </div>
                            <div className="Video">
                                <img src={Back} alt="Video" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="foot">
                    <footer className="container">
                        <div className="inpay">
                            <h4>Instant Pay</h4>
                            {/* <span>&copy; 1999-2020</span><a>Privacy Policy Terms and Conditions</a> */}
                        </div>
                        <div className="foryou">
                            <ul>
                                <li>
                                    <Link to="/aboutUs">
                                        <p>About Us</p>
                                    </Link>
                                    <Link to="/contactus">
                                        <p>Contact Us</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/policies">
                                        <p>Policies</p>
                                    </Link>
                                    <Link to="/faq">
                                        <p>FAQ</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/apiInformation">
                                        <p>API Information</p>
                                    </Link>
                                    <Link to="/termsAndCondition">
                                        <p>Terms & Conditions</p>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="signbtn">
                            <ul>
                                <li><a className="fa fa-facebook" href="https://www.facebook.com/" target="_blank"></a></li>
                                <li><a className="fa fa-twitter" href="https://twitter.com/" target="_blank"></a></li>
                                <li><a className="fa fa-linkedin" href="https://in.linkedin.com/" target="_blank"></a></li>
                                <li><a className="fa fa-instagram" href="https://www.instagram.com/" target="_blank"></a></li>
                                <li><a className="fa fa-youtube-play" href="https://www.youtube.com/" target="_blank"></a></li>
                            </ul>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

export default signUpHome
