import React, { Component } from 'react';
import Check from "./../../assets/Images/check.png";
import { Link } from "react-router-dom";

export class merchantSubscription extends Component {
    render() {
        return (
            <div className="merSubsc">
                <h3 className="Usermana">Choose New Plan</h3>
                <div className="row mt-4">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div className="card">
                            <h3>Free</h3>
                            <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                            <h4>AUS 0 / Month</h4>
                            <select style={{visibility:"hidden"}}>
                                <option>Select Validity</option>
                                <option>Select Validity 1</option>
                                <option>Select Validity 2</option>
                            </select>
                            <button type="button" className="start">Start with Free</button>
                            <div className="cardtext">
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div className="card">
                            <h3>One Click</h3>
                            <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                            <h4>AUS 99 / Month</h4>
                            <select>
                                <option>Select Validity</option>
                                <option>Select Validity 1</option>
                                <option>Select Validity 2</option>
                            </select>
                            <Link to="/merchant/planPayment">
                                <button type="button" className="start">Choose Plan</button>
                            </Link>
                            <div className="cardtext">
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div className="card">
                            <h3>Split Payment</h3>
                            <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                            <h4>AUS 149 / Month</h4>
                            <select>
                                <option>Select Validity</option>
                                <option>Select Validity 1</option>
                                <option>Select Validity 2</option>
                            </select>
                            <button type="button" className="start">Choose Plan</button>
                            <div className="cardtext">
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div className="card active">
                            <h3>Combo
                                <span>( One Click & Split Payment )</span>
                            </h3>
                            <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                            <h4>AUS 199 / Month</h4>
                            <select>
                                <option>Select Validity</option>
                                <option>Select Validity 1</option>
                                <option>Select Validity 2</option>
                            </select>
                            <button type="button" className="start">Active Plan</button>
                            <div className="cardtext">
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                                <label><img src={Check} alt="Check" />Lorem Ipsum is simply dummy text.</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default merchantSubscription
