import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";

///-------------------Css-------------------------------
import "react-notifications/lib/notifications.css";
import "react-responsive-modal/styles.css";
import "antd/dist/antd.css";
import "./assets/css/style.css";
import "./assets/css/custome.css";
import "./assets/css/responsive.css";

///--------------------js---------------------------------
import "./../node_modules/bootstrap/dist/js/bootstrap.js";
import "./../node_modules/jquery/dist/jquery.js";
import "./../node_modules/popper.js/dist/popper.js";
import "./../node_modules/bootstrap/dist/js/bootstrap.min.js";

import SignIn from "./component/Admin/SignIn";
import AdminForgotPassword from "./component/Admin/ForgotPassword";
import Admin from "./../src/routers/Admin/index";
import Merchant from "./../src/routers/MerchantMarketing/index";
import User from "./../src/routers/User/index";
import ResetPassword from "./component/Admin/ResetPassword";
import ChangePassword from "./component/Admin/changePassword";
import home from "./routers/MerchantMarketing/home";
import contactus from "./routers/MerchantMarketing/contactus";
import apiInformation from "./routers/MerchantMarketing/apiInformation";
import signUpHome from "./routers/MerchantMarketing/signUpHome";
import signUp from "./routers/MerchantMarketing/signUp";
import merchantLogin from './routers/MerchantMarketing/merchantLogin';
import userSignUp from "./routers/User/userSignUp";
import userLogin from "./routers/User/userLogin";
import aboutUs from "./routers/MerchantMarketing/aboutUs";
import faq from "./routers/MerchantMarketing/faq";
import termsAndCondition from "./routers/MerchantMarketing/termsAndCondition";
import policies from "./routers/MerchantMarketing/policies";
import setUp from "./routers/MerchantMarketing/setUp";
import help from "./routers/MerchantMarketing/help";
import whyInstant from "./routers/MerchantMarketing/whyInstant";

ReactDOM.render(
  <Router>
    <Route exact path="/" component={SignIn} />
    <Route exact path="/ForgotPassword" component={AdminForgotPassword} />
    <Route exact path="/ResetPassword" component={ResetPassword} />
    <Route exact path="/changePassword" component={ChangePassword} />
    <Route path="/admin" component={Admin} />
    <Route path="/merchant" component={Merchant} />
    <Route path="/user" component={User} />
    {/* MerchantMarketing Pages */}
    <Route exact path="/home" component={home} />
    <Route exact path="/contactus" component={contactus} />
    <Route exact path="/apiInformation" component={apiInformation} />
    <Route exact path="/signUpHome" component={signUpHome} />
    <Route exact path="/signUp" component={signUp} />
    <Route exact path="/merchantLogin" component={merchantLogin} />
    {/* User Pages */}
    <Route exact path="/userSignUp" component={userSignUp} />
    <Route exact path="/userLogin" component={userLogin} />
    <Route exact path="/aboutUs" component={aboutUs} />
    <Route exact path="/faq" component={faq} />
    <Route exact path="/policies" component={policies} />
    <Route exact path="/termsAndCondition" component={termsAndCondition} />
    <Route exact path="/setUp" component={setUp} />
    <Route exact path="/help" component={help} />
    <Route exact path="/whyInstant" component={whyInstant} />
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
