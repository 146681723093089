import React, { Component } from "react";
import Total from "./../../assets/Images/total.png";
import InfoIcon from "./../../assets/Images/Infoblue.png";
import Visa from "./../../assets/Images/visa.png";
import MasterCard from "./../../assets/Images/mastercard.png";
import AmericanExpress from "./../../assets/Images/american-express.png";
import DinerClub from "./../../assets/Images/diners-club.png";
import { Table, Popover } from "antd";
import Modal from "react-responsive-modal";
import config from "../../helpers/config";
import { authHeader } from "../../helpers/authHeader";
import axios from "axios";
import Down from "./../../assets/Images/download.png";

class userDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dashlog: false,
      totalSuccessTransaction: 0,
      totalFailedTransaction: 0,
      totalPendingRefund: 0,
      totalAmountTransaction: 0,
      gridData: [],
      mobileView: false,
    };
  }
  handledashlogmodalOpen() {
    this.setState({ dashlog: true });
  }
  handledashlogmodalClose() {
    this.setState({ dashlog: false });
  }
  componentDidMount() {
    this.handleGetDashboardCardsData();
    this.handleGetDashboardGridData();
    if (window.screen.width > 768) {
      this.setState({
        mobileView: false,
      });
    } else {
      this.setState({
        mobileView: true,
      });
    }
  }
  handleGetDashboardCardsData() {
    let self = this;

    axios({
      method: "get",
      url: config.apiUrl + "UserDashboard/CustomerDashboardCarts",
      headers: authHeader(),
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            totalSuccessTransaction: data.totalSuccessTransaction,
            totalFailedTransaction: data.totalFailedTransaction,
            totalPendingRefund: data.totalPendingRefund,
            totalAmountTransaction: data.totalAmountTransaction,
          });
        } else {
          self.setState({
            totalSuccessTransaction: 0,
            totalFailedTransaction: 0,
            totalPendingRefund: 0,
            totalAmountTransaction: 0,
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  // handleInstallmentsClickPage(merchantId) {
  //   debugger;
  //   let self = this;

  //   setTimeout(function () {
  //     self.props.history.push({
  //       pathname: "adminMerchantManagementDetails",
  //       merchantId: merchantId,
  //     });
  //   }, 1000);
  // }

  handleInstallmentsDetails(installmentsDetails) {
    debugger;

    // setTimeout(function () {
    this.props.history.push({
      pathname: "installmentDetails",
      installmentsDetails: installmentsDetails,
    });
    // }, 1000);
  }

  handleGetDashboardGridData() {
    let self = this;

    // let formData = new FormData();
    // formData.append("OrderID", null);
    // formData.append("TransactionID", null);
    // formData.append("TransactionDateFrom", null);
    // formData.append("TransactionDateTo", null);
    // formData.append("TransactionAmount", 0.0);
    // formData.append("UserName", null);
    // formData.append("MerchantName", null);
    // formData.append("PaymentProcessor", null);
    // formData.append("PaymentMethod", null);
    // formData.append("CustomerCard", null);
    // formData.append("PaymentStatus", null);

    // let formData = new FormData();
    // let abc = {
    //   OrderID: null,
    //   TransactionID: null,
    //   TransactionDateFrom: null,
    //   TransactionDateTo: null,
    //   TransactionAmount: 0.0,
    //   UserName: null,
    //   MerchantName: null,
    //   PaymentProcessor: null,
    //   PaymentMethod: null,
    //   CustomerCard: null,
    //   PaymentStatus: null,
    // };
    // formData.append("customertransactionhistory", abc);

    axios({
      method: "post",
      url: config.apiUrl + "UserTransaction/CustomerTransactionHistory",
      headers: authHeader(),
      data: {
        OrderID: null,
        TransactionID: null,
        TransactionDateFrom: null,
        TransactionDateTo: null,
        TransactionAmount: 0.0,
        UserName: null,
        MerchantName: null,
        PaymentProcessor: null,
        PaymentMethod: null,
        CustomerCard: null,
        PaymentStatus: null,
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            gridData: data.splice(0, 5),
          });
        } else {
          self.setState({
            gridData: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }
  render() {
    const merchantinfo = (
      <div className="userDashpopover">
        <div className="subsc">
          <label>Bank Name</label>
          <label>ICICI Bank</label>
        </div>
        <div className="subsc">
          <label>Bank Branch</label>
          <label>Kandivali</label>
        </div>
      </div>
    );
    const columns = [
      {
        title: "No.",
        dataIndex: "no",
        key: "no",
        render: (text, record, index) => index + 1,
      },
      {
        title: "Order ID",
        dataIndex: "orderId",
        key: "orderId",
      },
      {
        title: "Transaction ID",
        dataIndex: "transactionID",
        key: "transactionID",
        className: "mob-none",
      },
      {
        title: "Transaction Date",
        dataIndex: "transactionDate",
        key: "transactionDate",
        className: "mob-none",
      },
      {
        title: "Installments",
        dataIndex: "installment",
        key: "installment",
        className: "mob-none",
        render: (row, item) =>
          item.installment === "-" ? (
            item.installment
          ) : (
            <span
              className="custom-link"
              onClick={() =>
                this.handleInstallmentsDetails({
                  orderId: item.orderId,
                  merchantId: item.merchantId,
                })
              }
            >
              {item.installment}
            </span>
          ),
      },
      {
        title: "Amount Transacted (AU$)",
        dataIndex: "transactionAmount",
        key: "transactionAmount",
        className: "mob-none",
      },
      {
        title: "Merchant",
        dataIndex: "merchantName",
        key: "merchantName",
        className: "mob-none",
      },
      {
        title: "Payment Processor",
        dataIndex: "paymentProcessor",
        key: "paymentProcessor",
        className: "mob-none",
      },
      {
        title: "Payment Method ",
        dataIndex: "customerCard",
        key: "customerCard",
        className: "mob-none",
        render: (row, item) =>
          item.customerCard.toLowerCase().trim() === "visa" ? (
            <img src={Visa} alt="icon missing" className="cards-icon" />
          ) : item.customerCard.toLowerCase().trim() === "master card" ? (
            <img src={MasterCard} alt="icon missing" className="cards-icon" />
          ) : item.customerCard.toLowerCase().trim() === "american express" ? (
            <img
              src={AmericanExpress}
              alt="icon missing"
              className="cards-icon"
            />
          ) : item.customerCard.toLowerCase().trim() === "diner club" ? (
            <img src={DinerClub} alt="icon missing" className="cards-icon" />
          ) : (
            item.customerCard
          ),
      },
      {
        title: "Status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        className: "mob-none",
        render: (row, item) => {
          return (
            <div className="amazontext">
              <label
                className={
                  item.paymentStatus.toLowerCase().trim() === "failed"
                    ? "failed"
                    : item.paymentStatus.toLowerCase().trim() === "success"
                    ? "success"
                    : item.paymentStatus.toLowerCase().trim() === "progress"
                    ? "custom-link"
                    : "refundtext"
                }
              >
                {item.paymentStatus}
              </label>
              <Popover
                content={
                  <div className="refundpopover">
                    <div className="subsc">
                      <label>Refund Amount</label>
                      <label>{item.refundAmount}</label>
                    </div>
                    <div className="subsc">
                      <label>Refund Date</label>
                      <label>{item.refundedDate}</label>
                    </div>
                    <div className="subsc">
                      <label>Refund Initiated By</label>
                      <label>{item.refundInitiated}</label>
                    </div>
                  </div>
                }
                placement="bottom"
                trigger="hover"
              >
                {item.paymentStatus === "Refunded" && (
                  <img src={InfoIcon} alt="InfoIcon" />
                )}
              </Popover>
            </div>
          );
        },
      },
    ];

    const data = [
      {
        key: "1",
        no: "1",
        tranId: <label>TD1209</label>,
        trandate: <label>09-11-2020</label>,
        splitInst: <label>1/3</label>,
        totalamount: <label>10,234</label>,
        tranagainst: <label>Amazon</label>,
        paytype: <label>Credit Card</label>,
        paymentdet: (
          <div className="amazontext">
            <label>XXXX 678</label>
            <Popover content={merchantinfo} placement="bottom" trigger="click">
              <img src={InfoIcon} alt="InfoIcon" />
            </Popover>
          </div>
        ),
        status: (
          <div className="amazontext">
            <label className="pending">Progress</label>
          </div>
        ),
      },
      {
        key: "2",
        no: "2",
        tranId: <label>TD5678</label>,
        trandate: <label>25-05-2020</label>,
        splitInst: <label>-</label>,
        totalamount: <label>8,980</label>,
        tranagainst: <label>Flipkart</label>,
        paytype: <label>Debit Card</label>,
        paymentdet: (
          <div className="amazontext">
            <label>XXXX 678</label>
            <Popover content={merchantinfo} placement="bottom" trigger="click">
              <img src={InfoIcon} alt="InfoIcon" />
            </Popover>
          </div>
        ),
        status: (
          <div className="amazontext">
            <label className="success">Success</label>
          </div>
        ),
      },
      {
        key: "3",
        no: "3",
        tranId: <label>TD1234</label>,
        trandate: <label>09-11-2020</label>,
        splitInst: <label>-</label>,
        totalamount: <label>678</label>,
        tranagainst: <label>Groofers</label>,
        paytype: <label>Internet Banking</label>,
        paymentdet: (
          <div className="amazontext">
            <label>XXXX 678</label>
            <Popover content={merchantinfo} placement="bottom" trigger="click">
              <img src={InfoIcon} alt="InfoIcon" />
            </Popover>
          </div>
        ),
        status: (
          <div className="amazontext">
            <label className="failed">Failed</label>
          </div>
        ),
      },
      {
        key: "4",
        no: "4",
        tranId: <label>TD1209</label>,
        trandate: <label>20-09-2020</label>,
        splitInst: <label>2/3</label>,
        totalamount: <label>10,234</label>,
        tranagainst: <label>Amazon</label>,
        paytype: <label>Credit Card</label>,
        paymentdet: (
          <div className="amazontext">
            <label>XXXX 678</label>
            <Popover content={merchantinfo} placement="bottom" trigger="click">
              <img src={InfoIcon} alt="InfoIcon" />
            </Popover>
          </div>
        ),
        status: (
          <div className="amazontext">
            <label className="pending">Progress</label>
          </div>
        ),
      },
      {
        key: "5",
        no: "5",
        tranId: <label>TD5678</label>,
        trandate: <label>19-12-2020</label>,
        splitInst: <label>-</label>,
        totalamount: <label>8,980</label>,
        tranagainst: <label>Flipkart</label>,
        paytype: <label>Debit Card</label>,
        paymentdet: (
          <div className="amazontext">
            <label>XXXX 678</label>
            <Popover content={merchantinfo} placement="bottom" trigger="click">
              <img src={InfoIcon} alt="InfoIcon" />
            </Popover>
          </div>
        ),
        status: (
          <div className="amazontext">
            <label className="success">Success</label>
          </div>
        ),
      },
      {
        key: "6",
        no: "6",
        tranId: <label>TD1234</label>,
        trandate: <label>09-11-2020</label>,
        splitInst: <label>1/3</label>,
        totalamount: <label>678</label>,
        tranagainst: <label>Groofers</label>,
        paytype: <label>Internet Banking</label>,
        paymentdet: (
          <div className="amazontext">
            <label>XXXX 678</label>
            <Popover content={merchantinfo} placement="bottom" trigger="click">
              <img src={InfoIcon} alt="InfoIcon" />
            </Popover>
          </div>
        ),
        status: (
          <div className="amazontext">
            <label className="failed">Failed</label>
          </div>
        ),
      },
    ];
    return (
      <div>
        <div className="Userdashboard">
          <h3 className="dash">Dashboard</h3>
          <div className="row mt-4">
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card back1">
                <div className="image">
                  <img src={Total} alt="Card1" />
                </div>
                <div className="text">
                  <label>{this.state.totalSuccessTransaction}</label>
                  <label>Total Successful Transactions</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card back2">
                <div className="image">
                  <img src={Total} alt="Card1" />
                </div>
                <div className="text">
                  <label>{this.state.totalFailedTransaction}</label>
                  <label>Total Failed Transactions</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card back3">
                <div className="image">
                  <img src={Total} alt="Card1" />
                </div>
                <div className="text">
                  <label>{this.state.totalPendingRefund}</label>
                  <label>Pending Refunds</label>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="card back4">
                <div className="image">
                  <img src={Total} alt="Card1" />
                </div>
                <div className="text">
                  <label>{this.state.totalAmountTransaction}</label>
                  <label>Total Amount Spent using Instant Pay</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="Userdashtable">
                <Table
                  columns={columns}
                  expandedRowRender={(row) => {
                    return (
                      <React.Fragment>
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Order ID:</label>
                              <label className="expandemailtext">{row.orderId}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Transaction ID:</label>
                              <label className="expandemailtext">{row.transactionID}</label>
                            </div>
                          </div>
                        </div>
      
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Transaction Date:</label>
                              <label className="expandemailtext">{row.transactionDate}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Installments:</label>
                              <label className="expandemailtext">{row.installment}</label>
                            </div>
                          </div>
                        </div>
      
                         <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Amount Transacted (AU$):</label>
                              <label className="expandemailtext">{row.transactionAmount}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Merchant:</label>
                              <label className="expandemailtext">{row.merchantName}</label>
                            </div>
                          </div>
                        </div>
      
                         <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Payment Processor:</label>
                              <label className="expandemailtext">{row.paymentProcessor}</label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Payment Method:</label>
                              <label className="expandemailtext">{row.customerCard}</label>
                            </div>
                          </div>
                        </div>
      
                        <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Status:</label>
                              <label className="expandemailtext">{row.paymentStatus}</label>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-12 col-sm-6 mb-3">
                            <div className="mobilevi">
                              <label className="expandemail">Status:</label>
                              <label className="expandemailtext">
                                {row.isActive ? "Active" : "Inactive"}
                              </label>
                            </div>
                          </div>
                        </div> */}
                      </React.Fragment>
                    );
                  }}
                  expandIcon={ ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <div className="expandown1">
                      <img src={Down} onClick={e => onExpand(record, e)} />
                    </div>
                  ) : (
                    <div className="expandown">
                      <img src={Down} onClick={e => onExpand(record, e)} />
                    </div>
                  )}
                  expandIconColumnIndex={this.state.mobileView ? 10 : -1}
                  expandIconAsCell={false}
                  dataSource={this.state.gridData}
                  // onRow={(row, item) => ({
                  //   onClick:
                  //     row.installment === "-"
                  //       ? null
                  //       : () => this.handleInstallmentsClickPage(row),
                  // })}
                  pagination={{position:["bottomCenter"]}}
                />
              </div>
              {/* <div className="pagination">
                <ul>
                  <li>
                    <a hrf="">&lt;</a>
                  </li>
                  <li className="active">
                    <a hrf="">1</a>
                  </li>
                  <li>
                    <a hrf="">2</a>
                  </li>
                  <li>
                    <a hrf="">3</a>
                  </li>
                  <li>
                    <a hrf="">4</a>
                  </li>
                  <li>
                    <a hrf="">5</a>
                  </li>
                  <li>
                    <a hrf="">&gt;</a>
                  </li>
                </ul>
                <div className="selectopt">
                  <select>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </div>
              </div> */}
            </div>
          </div>
          <Modal
            open={this.state.dashlog}
            onClose={this.handledashlogmodalClose.bind(this)}
            modalId="dashmodaluser"
            overlayId="overlay"
          >
            <div className="backtext">
              <h3 className="eduser">Congratulation!!</h3>
            </div>
            <div className="edituser">
              <p>Add Card Details to enjoy our services.</p>

              <div className="Editbtn">
                <button className="btn">Add</button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default userDashboard;
