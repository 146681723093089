import React, { Component } from "react";
import CSV from "./../../assets/Images/csv.png";
import InfoIcon from "./../../assets/Images/info-icon.png";
import { Table, Popover } from "antd";
import config from "./../../helpers/config";
import axios from "axios";
import { authHeader } from "./../../helpers/authHeader";
import { CSVLink } from "react-csv";

class adminUserManagementDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: {},
      user_Id: 0,
      userTransactionDetails: [],
    };
  }

  componentDidMount() {
    var userId = this.props.location.userId;
    if (userId) {
      this.setState({ user_Id: userId });
      this.handleGetUserManagementDetails(userId);
    } else {
      this.props.history.push("adminUserManagement");
    }
  }

  handleGetUserManagementDetails(userId, searchAny) {
    let self = this;

    axios({
      method: "post",
      url: config.apiUrl + "Transaction/UserManagementTransactionDetails",
      headers: authHeader(),
      data: {
        userid: userId,
        search: searchAny,
        page: "1",
        size: "10",
      },
    })
      .then(function (res) {
        debugger;
        let status = res.data.message;
        let data = res.data.responseData;
        if (status === "Success") {
          self.setState({
            userData: data,
            userTransactionDetails: data.userTransactions,
          });
        } else {
          self.setState({
            userData: {},
            userTransactionDetails: [],
          });
        }
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleUserDataSearch(e) {
    // this.setState({
    //   [e.target.name]: e.target.value,
    // });
    this.handleGetUserManagementDetails(this.state.user_Id, e.target.value);
  }

  render() {
    const headers = [
      { label: "Transactions Id", key: "userTransactionId" },
      { label: "Transactions Date", key: "userTransactionDate" },
      { label: "Amount Transacted (AU$)", key: "userTransactionAmount" },
      { label: "Transacted Against", key: "userTransactionMerchant" },
      { label: "Payment Method", key: "userTransactionPaymentMethod" },
      { label: "Status", key: "userTransactionStatus" },
    ];

    const columns = [
      {
        title: "No.",
        dataIndex: "no",
        key: "no",
      },
      {
        title: "Transactions Id",
        dataIndex: "userTransactionId",
        key: "userTransactionId",
      },
      {
        title: "Transactions Date",
        dataIndex: "userTransactionDate",
        key: "userTransactionDate",
      },
      {
        title: "Amount Transacted (AU$)",
        key: "userTransactionAmount",
        dataIndex: "userTransactionAmount",
      },
      {
        title: "Transacted Against",
        key: "userTransactionMerchant",
        dataIndex: "userTransactionMerchant",
        render: (row, item) => {
          return (
            <span>
              <label>{item.userTransactionMerchant}</label>
              <Popover
                content={
                  <div>
                    <ul>
                      <li>
                        <p>Email</p>
                        <p></p>
                      </li>
                      <li>
                        <p>Contact Number</p>
                        <p></p>
                      </li>
                      <li>
                        <p>Total No. of Transactions</p>
                        <p></p>
                      </li>
                      <li>
                        <p>Subscription Type</p>
                        <p></p>
                      </li>
                      <li>
                        <p>Subscription Period</p>
                        <p></p>
                      </li>
                      <li>
                        <p>Subscription Status</p>
                        <p></p>
                      </li>
                    </ul>
                  </div>
                }
                placement="bottom"
              >
                <img className="info-icon" src={InfoIcon} alt="info-icon" />
              </Popover>
            </span>
          );
        },
      },
      {
        title: "Payment Method",
        key: "userTransactionPaymentMethod",
        dataIndex: "userTransactionPaymentMethod",
      },
      {
        title: "Status",
        key: "userTransactionStatus",
        dataIndex: "userTransactionStatus",
        render: (row, item) => {
          return (() => {
            if (item.userTransactionStatus == "Pending") {
              return (
                <label className="pending">{item.userTransactionStatus}</label>
              );
            } else if (item.userTransactionStatus == "Failed") {
              return (
                <label className="failed">{item.userTransactionStatus}</label>
              );
            } else if (item.userTransactionStatus == "Success") {
              return (
                <label className="success">{item.userTransactionStatus}</label>
              );
            }
          })();
          // return (
          //   <label className="pending">Pending</label>
          //   <label className="failed">Failed</label>
          //   <label className="success">Success</label>
          // );
        },
      },
    ];

    const userDetails = this.state.userData;
    return (
      <div className="usermanadetail">
        <h3 className="Usermana">End Customer Details</h3>
        <div className="row mt-3 usdetailtext">
          <div className="col-12 col-md-4">
            <label>Name</label>
            <label>{userDetails.userName}</label>
          </div>
          <div className="col-12 col-md-4">
            <label>Email</label>
            <label>{userDetails.userEmailId}</label>
          </div>
          <div className="col-12 col-md-4">
            <label>Contact No.</label>
            <label>{userDetails.userContactNo}</label>
          </div>
        </div>
        <div className="row usdetailtext">
          <div className="col-12 col-md-4">
            <label>DOB</label>
            <label>09-10-2020</label>
          </div>
          <div className="col-12 col-md-4">
            <label>Address</label>
            <label>
              Lorem IpSum Lorem IpSum Lorem IpSum Lorem IpSum Lorem IpSum Lorem
              IpSum Lorem IpSumLorem
            </label>
          </div>
          <div className="col-12 col-md-4">
            <label>Saved Cards</label>
            <label>1.Master Card ending with 4567</label>
            <label>2.Credit Card ending with 9087</label>
          </div>
        </div>
        <div className="row mb-4 usdetailtext">
          <div className="col-12 col-md-4">
            <label>Total No. of Transactions</label>
            <label>{userDetails.userTotalTransactionCount}</label>
          </div>
          <div className="col-12 col-md-4">
            <label>Total Amount Transacted (AU$)</label>
            <label>{userDetails.userTotalTransactionAmount}</label>
          </div>
          <div className="col-12 col-md-4">
            <label>Status</label>
            <label>{userDetails.status == true ? "Active" : "Inactive"}</label>
          </div>
        </div>
        <div className="exfilter">
          <input
            type="text"
            placeholder="Search Anything"
            onChange={this.handleUserDataSearch.bind(this)}
          />
          <CSVLink
            data={this.state.userTransactionDetails}
            headers={headers}
            filename={"User Details.csv"}
            className="csv"
          >
            <img src={CSV} alt="Export" />
            Export to CSV
          </CSVLink>
        </div>
        <div className="usermanadetailtable">
          <Table
            columns={columns}
            dataSource={this.state.userTransactionDetails}
            pagination={{position:["bottomCenter"]}}
          />
        </div>
      </div>
    );
  }
}

export default adminUserManagementDetails;
