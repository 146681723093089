import React, { Component } from "react";
import Back from "../../assets/Images/sign-in-bg.jpg";
import Instant from "../../assets/Images/Instant-Pay-User-Management.png";
import { Link } from "react-router-dom";
import config from "./../../helpers/config";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      staticContent: [],
    };
  }

  componentDidMount() {
    this.handleGetStaticContent();
  }

  handleGetStaticContent() {
    let self = this;

    axios({
      method: "get",
      url: config.apiUrl + "MerchantMarketing/MerchantHomePageStaticData",
    })
      .then(function (res) {
        debugger;
        let data = res.data.responseData;
        self.setState({
          staticContent: data,
        });
      })
      .catch((data) => {
        console.log(data);
      });
  }

  handleLearnMoreClick(type, text) {
    let self = this;
    setTimeout(function () {
      self.props.history.push({
        pathname: "whyInstant",
        type: type,
        text: text
      });
    }, 1000);
  }

  render() {
    const { staticContent } = this.state;
    return (
      <div className="marketingHome">
        <div className="merchantMar">
          <header>
            <div className="inpay">
              <Link to="/home">
                <h4>Instant Pay</h4>
              </Link>
            </div>
            <div className="foryou">
              <ul>
                <li>
                  <Link to="/userSignUp">
                    <a>For You</a>
                  </Link>
                </li>
                <li>
                  <Link to="/signUp">
                    <a>For Your Business</a>
                  </Link>
                </li>
                <li>
                  <Link to="/setUp">
                    <a>Set - Up</a>
                  </Link>

                </li>
                <li>
                  <Link to="/help">
                    <a>Help</a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="signbtn">
              <Link to="/">
                <button type="button" className="btn sigin">
                  Sign In
                </button>
              </Link>
              <Link to="/signUpHome">
                <button type="button" className="btn sigup">
                  Sign Up
                </button>
              </Link>
            </div>
          </header>
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            autoPlay
            swipeable
            emulateTouch
            stopOnHover
            infiniteLoop
          >
            {staticContent.filter(e => e.contentType == "Content").map(item => (
              <div>
                <img src={Back} />
                <div className="carousel-caption">
                  <h4>{item.title}</h4>
                  <p>{item.contentText}</p>
                  <Link to="/">
                    <button>Sign Up</button>
                  </Link>
                </div>
              </div>))}
            {/* //  <div>
            //   <img src={Back} />
            //   <div className="carousel-caption">
            //     <h4>Easy to use</h4>
            //     <p>{staticContent.content2}</p>
            //     <Link to="/merchantLogin">
            //       <button>Sign Up</button>
            //     </Link>
            //   </div>
            // </div>
            // <div>
            //   <img src={Back} />
            //   <div className="carousel-caption">
            //     <h4>Fully Trustable &amp; Reliable</h4>
            //     <p>{staticContent.content3}</p>
            //     <Link to="/merchantLogin">
            //       <button>Sign Up</button>
            //     </Link>
            //   </div>
            // </div> */}
          </Carousel>
        </div>
        <div className="container topbottom">
          <h3>Why Instant ?</h3>
          <div className="row">
          {staticContent.filter(e => e.contentType == "Why Instant").map(item => (
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="Safar text-center">
                <div className="safarimg">
                  <img src={Instant} alt="safar" />
                </div>
                <h4>{item.title}</h4>
                <p>{item.contentText}</p>
                <button type="button" onClick={this.handleLearnMoreClick.bind(this, item.title, item.contentText)}>Learn More</button>
              </div>
            </div>))}
            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="Safar text-center">
                <div className="safarimg">
                  <img src={Instant} alt="Flexible" />
                </div>
                <h4>Flexible</h4>
                <p>{staticContent.field2}</p>
                <button type="button" onClick={this.handleLearnMoreClick.bind(this, "Flexible", staticContent.field2)}>Learn More</button>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="Safar text-center">
                <div className="safarimg">
                  <img src={Instant} alt="Convenient" />
                </div>
                <h4>Convenient</h4>
                <p>{staticContent.field3}</p>
                <button type="button" onClick={this.handleLearnMoreClick.bind(this, "Convenient", staticContent.field3)}>Learn More</button>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="Safar text-center">
                <div className="safarimg">
                  <img src={Instant} alt="Ptotected" />
                </div>
                <h4>Protected</h4>
                <p>{staticContent.field4}</p>
                <button type="button" onClick={this.handleLearnMoreClick.bind(this, "Protected", staticContent.field4)}>Learn More</button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="topbotto">
          <div className="container">
            <h3>Instant is used by</h3>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div className="Safarnew">
                  <h3>Zomato</h3>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div className="Safarnew">
                  <h3>Ola</h3>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div className="Safarnew">
                  <h3>Netflix</h3>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div className="Safarnew">
                  <h3>Make my trip</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div className="Safarnew">
                  <h3>Flipkart</h3>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div className="Safarnew">
                  <h3>Pizza Hut</h3>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div className="Safarnew">
                  <h3>Gaana</h3>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div className="Safarnew">
                  <h3>netmeds</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <footer className="container">
            <div className="inpay">
              <h4>Instant Pay</h4>
              {/* <span>&copy; 1999-2020</span>
              <a>Privacy Policy Terms and Conditions</a> */}
            </div>
            <div className="foryou">
              <ul>
                <li>
                  <Link to="/aboutUs">
                    <p>About Us</p>
                  </Link>
                  <Link to="/contactus">
                    <p>Contact Us</p>
                  </Link>
                </li>
                <li>
                  <Link to="/policies">
                    <p>Policies</p>
                  </Link>
                  <Link to="/faq">
                    <p>FAQ</p>
                  </Link>
                </li>
                <li>
                  <Link to="/apiInformation">
                    <p>API Information</p>
                  </Link>
                  <Link to="/termsAndCondition">
                    <p>Terms & Conditions</p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="signbtn">
              <ul className="footerlink">
                <li>
                  <a className="fa fa-facebook" href="https://www.facebook.com/" target="_blank"></a>
                </li>
                <li>
                  <a className="fa fa-twitter" href="https://twitter.com/" target="_blank"></a>
                </li>
                <li>
                  <a className="fa fa-linkedin" href="https://in.linkedin.com/" target="_blank"></a>
                </li>
                <li>
                  <a className="fa fa-instagram" href="https://www.instagram.com/" target="_blank"></a>
                </li>
                <li>
                  <a className="fa fa-youtube-play" href="https://www.youtube.com/" target="_blank"></a>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default home;
